import { Table, TableRow, TableCell, TableBody, IconButton, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { getShortDate } from "utils/common";
import { Allegato } from "types/allegato";
import DownloadIcon from '@mui/icons-material/Download';

interface AttachmentsListMobileProps {
    files: Allegato[];
    downloadAllegato: (file: any) => void;
}

const AttachmentsListMobile: FC<AttachmentsListMobileProps> = (props) => {
    const { files, downloadAllegato } = props;

    return (
        <Table size="small">
            <TableBody>
                {
                    files &&
                    files.length > 0 &&
                    files.map((file: Allegato) => (
                        <TableRow>
                            <TableCell>
                                <Grid container direction="column">
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="attachmentName" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {file.nomeFile} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="dateOfInsert" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {getShortDate(file.dateOfInsert)} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell
                                sx={{ verticalAlign: 'center', textAlign: 'right' }}
                            >
                                <IconButton onClick={() => downloadAllegato(file)}>
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    )}
            </TableBody>
        </Table>
    )
}

export default AttachmentsListMobile;