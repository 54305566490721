import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { AgenziaService } from "services/AgenziaServices";
import { DecuiusService } from "services/DecuiusServices";
import { GeoService } from "services/GeoServices";
import { Agenzia } from "types/agenzia";
import { Comune } from "types/comune";
import { Decuius, defaultDecuius } from "types/decuius";
import { StatoDecuius } from "types/enums/StatoDecuius";
import { Nazione } from "types/nazione";
import { Provincia } from "types/provincia";
import { Regione } from "types/regione";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage, getFiscalCode, getFormValue, isNotNullOrUndefined, toFirstUpper } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import { IconFiles } from '@tabler/icons';
import dayjs from "dayjs";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DecuiusInfo } from "types/decuiusInfo";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DecuiusPlaceInfo } from "types/decuiusPlaceInfo";
import { StructureShortInfo } from "types/structureShortInfo";
import { StrutturaService } from "services/StrutturaServices";
import IAutocompleteOptions from "interfaces/IAutocompleteOptions";
import { SteccaService } from "services/SteccaServices";
import CloseIcon from '@mui/icons-material/Close';
import { SteccaShortInfo } from "types/steccaShortInfo";
import { PostoSteccaView } from "types/postoSteccaView";
import { PostoService } from "services/PostoServices";
import { StatoPosto } from "types/enums/StatoPosto";

interface DecuiusUpsertProps {
	isAdd: boolean;
}

const DecuiusUpsert: FC<DecuiusUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const decuiusService = new DecuiusService();
	const strutturaService = new StrutturaService();
	const steccaService = new SteccaService();
	const postoService = new PostoService();

	const [decuius, setDecuius] = useState<Decuius>(defaultDecuius);
	const [decuiusPlaceInfo, setDecuiusPlaceInfo] = useState<DecuiusPlaceInfo | null>(null);

	const agenziaService = new AgenziaService();
	const geoService = new GeoService();

	const [agenzie, setAgenzie] = useState<Agenzia[]>([]);

	const [countryOptions, setCountryOptions] = useState<Nazione[]>([]);

	const [selectedBirthRegion, setSelectedBirthRegion] = useState<string>('');
	const [selectedBirthProvince, setSelectedBirthProvince] = useState<string>('');
	const [selectedBirthCity, setSelectedBirthCity] = useState<string>('');

	const [selectedDeathProvince, setSelectedDeathProvince] = useState<string>('');
	const [selectedDeathRegion, setSelectedDeathRegion] = useState<string>('');
	const [selectedDeathCity, setSelectedDeathCity] = useState<string>('');

	const [selectedResidenceProvince, setSelectedResidenceProvince] = useState<string>('');
	const [selectedResidenceRegion, setSelectedResidenceRegion] = useState<string>('');
	const [selectedResidenceCity, setSelectedResidenceCity] = useState<string>('');

	const [enableBirthAdditionalFields, setEnableBirthAdditionalFields] = useState<boolean>(true);
	const [enableDeathAdditionalFields, setEnableDeathAdditionalFields] = useState<boolean>(true);
	const [enableResidenceAdditionalFields, setEnableResidenceAdditionalFields] = useState<boolean>(true);

	const [regions, setRegions] = useState<Regione[]>([]);

	const [birthProvinces, setBirthProvinces] = useState<Provincia[]>([]);
	const [birthCities, setBirthCities] = useState<Comune[]>([]);

	const [deathProvinces, setDeathProvinces] = useState<Provincia[]>([]);
	const [deathCities, setDeathCities] = useState<Comune[]>([]);

	const [residenceProvinces, setResidenceProvinces] = useState<Provincia[]>([]);
	const [residenceCities, setResidenceCities] = useState<Comune[]>([]);

	let decuiusValidationSchema = object({
		cognome: string().required('surnameRequired'),
		nome: string().required('nameRequired'),
	});

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	const getDecuiusData = async () => {
		let decuiusId: string = window.location.href.split('decuiusId=')[1] ?? undefined;
		if (decuiusId != undefined) {
			decuiusId = decuiusId.split('&')[0]
		}
		let retrievedDecuiusInfo: DecuiusInfo = await decuiusService.GetDecuiusById(decuiusId);

		const decuius: Decuius | undefined = retrievedDecuiusInfo.decuius;

		setDecuiusPlaceInfo(retrievedDecuiusInfo.decuiusPlaceInfo);

		if (isNotNullOrUndefined(decuius)) {
			setDecuius(decuius!);

			setSelectedBirthRegion(decuius!.regioneNascitaId ?? '');

			setSelectedDeathRegion(decuius!.regioneMorteId ?? '');

			setSelectedResidenceRegion(decuius!.regioneResidenzaId ?? '');
		} else {
			navigate('/decuius');
		}

		const retrievedStructuresShortInfo: StructureShortInfo[] = await strutturaService.GetStructuresShortInfo("");
		setStructuresShortInfoOptions(retrievedStructuresShortInfo.map((structureShortInfo: StructureShortInfo) => { return { id: structureShortInfo.id, label: structureShortInfo.name } }));
	}

	useEffect(() => {
		setIsPageLoading(true);

		(async () => {
			setAgenzie(await agenziaService.GetUnpaginatedAgenzie());
			setRegions(await geoService.GetRegioni());
			setCountryOptions(await geoService.GetNazioni());

			if (!isAdd) {
				await getDecuiusData();
			}

			let postoId: string = window.location.href.split('postoId=')[1] ?? undefined;
			if (postoId != undefined) {
				postoId = postoId.split('&')[0]
			}
			setPostoId(postoId);

			let decuiusType: string = window.location.href.split('decuiusType=')[1] ?? undefined;
			setDecuiusType(decuiusType);

			if (decuiusType !== undefined) {
				setDecuius((prevStatus) => ({ ...prevStatus, stato: Number(decuiusType) }))
			}

			setIsPageLoading(false);
		})();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setDecuius((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const handleDateChange = (fieldName: string, date: any) => {
		let newValue: string | null;

		newValue = dayjs(date).format('YYYY-MM-DD');

		newValue = newValue == 'Invalid Date' ? null : newValue;

		setDecuius((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
	}

	const decuiusUpsert = async () => {
		try {
			let redirectPath: string = '/decuius';

			await decuiusValidationSchema.validate(decuius, { abortEarly: false });

			let decuiusId: string = await decuiusService.UpsertDecuius(decuius);

			if (postoId !== undefined && decuiusType !== undefined) {
				let decuiusTypeString: string = Number(decuiusType) == StatoDecuius.Salma ? 'decuiusCorpseId' : 'decuiusBoxId';
				redirectPath = `/posto/edit?postoId=${postoId}&${decuiusTypeString}=${decuiusId}`;
			}

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			if (redirectPath !== '') navigate(redirectPath)
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	useEffect(() => {
		setEnableBirthAdditionalFields(toFirstUpper(decuius.statoNascita) == "Italia");
	}, [decuius.statoNascita])

	useEffect(() => {
		setEnableDeathAdditionalFields(toFirstUpper(decuius.statoMorte) == "Italia");
	}, [decuius.statoMorte])

	useEffect(() => {
		setEnableResidenceAdditionalFields(toFirstUpper(decuius.nazioneResidenza) == "Italia");
	}, [decuius.nazioneResidenza])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedBirthRegion)) {
			(async () => {
				const retrievedBirthProvinces = await geoService.GetProvinceByRegioneId(selectedBirthRegion);
				setBirthProvinces(retrievedBirthProvinces);

				if (!isAdd) {
					setSelectedBirthProvince(retrievedBirthProvinces.find((province: Provincia) => province.sigla == decuius.provinciaNascita?.toUpperCase())?.id!);
				}
			})()
		}
	}, [selectedBirthRegion])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedBirthProvince)) {
			(async () => {
				const retrievedBirthCities = await geoService.GetComuniByProvinciaId(selectedBirthProvince);
				setBirthCities(retrievedBirthCities);

				if (!isAdd) {
					setSelectedBirthCity(retrievedBirthCities.find((city: Comune) => city.nome == toFirstUpper(decuius.comuneNascita))?.id!);
				}
			})()
		}
	}, [selectedBirthProvince])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedDeathRegion)) {
			(async () => {
				const retrievedDeathProvinces = await geoService.GetProvinceByRegioneId(selectedDeathRegion);
				setDeathProvinces(retrievedDeathProvinces);

				if (!isAdd) {
					setSelectedDeathProvince(retrievedDeathProvinces.find((province: Provincia) => province.sigla == decuius.provinciaMorte?.toUpperCase())?.id!);
				}
			})()
		}
	}, [selectedDeathRegion])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedDeathProvince)) {
			(async () => {
				const retrievedDeathCities = await geoService.GetComuniByProvinciaId(selectedDeathProvince);
				setDeathCities(retrievedDeathCities);

				if (!isAdd) {
					setSelectedDeathCity(retrievedDeathCities.find((city: Comune) => city.nome == toFirstUpper(decuius.comuneMorte))?.id!);
				}
			})()
		}
	}, [selectedDeathProvince])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedResidenceRegion)) {
			(async () => {
				const retrievedResidenceProvinces = await geoService.GetProvinceByRegioneId(selectedResidenceRegion);
				setResidenceProvinces(retrievedResidenceProvinces);

				if (!isAdd) {
					setSelectedResidenceProvince(retrievedResidenceProvinces.find((province: Provincia) => province.sigla == decuius.provinciaResidenza?.toUpperCase())?.id!);
				}
			})()
		}
	}, [selectedResidenceRegion])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedResidenceProvince)) {
			(async () => {
				const retrievedResidenceCities = await geoService.GetComuniByProvinciaId(selectedResidenceProvince);
				setResidenceCities(retrievedResidenceCities);

				if (!isAdd) {
					setSelectedResidenceCity(retrievedResidenceCities.find((city: Comune) => city.nome == toFirstUpper(decuius.comuneResidenza))?.id!);
				}
			})()
		}
	}, [selectedResidenceProvince])

	const [postoId, setPostoId] = useState<string | undefined>(undefined);
	const [decuiusType, setDecuiusType] = useState<string | undefined>(undefined);

	const [deleteDecuiusDialogOpen, setDeleteDecuiusDialogOpen] = useState<boolean>(false);

	const deleteDecuius = async () => {
		try {
			await decuiusService.DeleteDecuius(decuius.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/decuius');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	const [transferDecuiusDialogOpen, setTransferDecuiusDialogOpen] = useState<boolean>(false);

	const [structuresShortInfoOptions, setStructuresShortInfoOptions] = useState<Array<IAutocompleteOptions>>([]);
	const [chosenStructure, setChosenStructure] = useState<IAutocompleteOptions | null>(null);

	const [steccheByStrutturaOptions, setSteccheByStrutturaOptions] = useState<Array<IAutocompleteOptions>>([]);
	const [chosenStecca, setChosenStecca] = useState<IAutocompleteOptions | null>(null);

	const [placesOptions, setPlacesOptions] = useState<Array<IAutocompleteOptions>>([]);
	const [chosenPlace, setChosenPlace] = useState<IAutocompleteOptions | null>(null);

	const [structureAutocompleteOpen, setStructureAutocompleteOpen] = useState<boolean>(false);
	const handleStructureAutocompleteChange = (value: IAutocompleteOptions | null) => {
		setChosenStructure(value);
	}

	const [steccaAutocompleteOpen, setSteccaAutocompleteOpen] = useState<boolean>(false);
	const handleSteccaAutocompleteChange = (value: IAutocompleteOptions | null) => {
		setChosenStecca(value);
	}

	const [placesAutocompleteOpen, setPlacesAutocompleteOpen] = useState<boolean>(false);
	const handlePlacesAutocompleteChange = (value: IAutocompleteOptions | null) => {
		setChosenPlace(value);
	}

	useEffect(() => {
		setChosenStecca(null);

		if (isNotNullOrUndefined(chosenStructure)) {
			(async () => {
				const steccheByStruttura: SteccaShortInfo[] = await steccaService.GetSteccheShortInfoByStrutturaId(chosenStructure!.id);
				setSteccheByStrutturaOptions(steccheByStruttura.map((stecca: SteccaShortInfo) => { return { id: stecca.id!, label: stecca.name } }))
			})()
		}
	}, [chosenStructure])

	useEffect(() => {
		setChosenPlace(null);
		if (isNotNullOrUndefined(chosenStecca)) {
			(async () => {
				let retrievedPlaces = await steccaService.GetPlacesShortInfoBySteccaId(chosenStecca!.id);

				const retrievedPlacesOptions = retrievedPlaces
					.filter((retrievedPlace: PostoSteccaView) => retrievedPlace.visibile)
					.map((retrievedPlace: PostoSteccaView) => {
						const ownerFullName: string = `${retrievedPlace.proprietarioNome} ${retrievedPlace.proprietarioCognome}`;
						let ownerType: string = "";

						switch (retrievedPlace.statoPosto) {
							case StatoPosto.AventeDiritto:
								ownerType = intl.formatMessage({ id: 'entitled' })
								break;
							case StatoPosto.Concessionario:
								ownerType = intl.formatMessage({ id: 'dealer' })
								break;
							case StatoPosto.Referente:
								ownerType = intl.formatMessage({ id: 'referent' })
								break;
						}

						const label = `${retrievedPlace.numero} - ${isNotNullOrUndefined(ownerFullName.trim()) ? `${ownerFullName} (${ownerType})` : intl.formatMessage({ id: 'free' })}`;

						return {
							id: retrievedPlace.id,
							label: label
						}
					})

				setPlacesOptions(retrievedPlacesOptions)
			})()
		}
	}, [chosenStecca])

	useEffect(() => {
		if (
			isNotNullOrUndefined(decuius.nome) &&
			isNotNullOrUndefined(decuius.cognome) &&
			isNotNullOrUndefined(decuius.dataNascita) &&
			isNotNullOrUndefined(decuius.sesso) &&
			isNotNullOrUndefined(decuius.statoNascita)
		) {
			try {
				const fiscalCode: string = getFiscalCode(decuius.nome, decuius.cognome, decuius.dataNascita!, decuius.sesso!, decuius.statoNascita!, decuius.provinciaNascita!, decuius.comuneNascita!);

				setDecuius((currentFormData: any) => ({ ...currentFormData, codiceFiscale: fiscalCode }));
			}
			catch (e: any) { }
		}
	}, [decuius.nome, decuius.cognome, decuius.dataNascita, decuius.sesso, decuius.statoNascita, decuius.provinciaNascita, decuius.comuneNascita])

	const transferDecuius = async () => {
		setIsPageLoading(true);

		try {
			await postoService.AssignDecuiusToPosto({
				postoId: chosenPlace?.id,
				decuiusId: decuius.id,
				statoDecuius: decuius.stato
			});

			setTransferDecuiusDialogOpen(false);

			await getDecuiusData();

			composeNotistackMessage(intl.formatMessage({ id: 'decuiusTransferredSuccessfully' }), 'success');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		} finally {
			setIsPageLoading(false);
		}
	}

	return (
		<Card>
			<DialogDeleteElement
				open={deleteDecuiusDialogOpen}
				onClose={() => setDeleteDecuiusDialogOpen(false)}
				onCancel={() => setDeleteDecuiusDialogOpen(false)}
				onConfirm={() => {
					setDeleteDecuiusDialogOpen(false)
					deleteDecuius();
				}}
				entityToDeleteInfo={`${decuius.nome} ${decuius.cognome}` ?? ''}
			/>
			<CardHeader title={<FormattedMessage id="registry" />} />
			<Divider />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container direction="row" spacing={1}>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="protocol" />}
										name="protocollo"
										onChange={handleChange}
										value={decuius.protocollo}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="surname" />}
										name="cognome"
										required
										onChange={handleChange}
										value={decuius.cognome}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="name" />}
										name="nome"
										required
										onChange={handleChange}
										value={decuius.nome}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby="gender-radio-buttons"
											name="sesso"
											onChange={handleChange}
											value={decuius.sesso}
										>
											<FormControlLabel value={2} control={<Radio />} label={<FormattedMessage id="female" />} />
											<FormControlLabel value={1} control={<Radio />} label={<FormattedMessage id="male" />} />
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<FormControl fullWidth size='small'>
										<InputLabel> <FormattedMessage id="agency" /> </InputLabel>
										<Select
											name="agenziaId"
											label={<FormattedMessage id="agency" />}
											onChange={handleChange}
											defaultValue={''}
											value={decuius.agenziaId}
											sx={{ color: 'white' }}
										>
											{
												agenzie && agenzie
													.slice()
													.map((agenzia) => (
														<MenuItem key={agenzia.id} value={agenzia.id}>
															{agenzia.denominazione}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<DatePicker
										inputFormat="DD/MM/YYYY"
										label={<FormattedMessage id="birthDate" />}
										onChange={(date: any) => {
											handleDateChange('dataNascita', date);
										}}
										renderInput={(params) => (
											<TextField fullWidth {...params} error={false} size='small' />
										)}
										value={decuius.dataNascita ?? ''}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<DatePicker
										inputFormat="DD/MM/YYYY"
										label={<FormattedMessage id="deathDate" />}
										onChange={(date: any) => {
											handleDateChange('dataMorte', date);
										}}
										renderInput={(params) => (
											<TextField fullWidth {...params} error={false} size='small' />
										)}
										value={decuius.dataMorte ?? ''}
									/>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth size='small'>
										<InputLabel> <FormattedMessage id="birthCountry" /> </InputLabel>
										<Select
											name="statoNascita"
											label={<FormattedMessage id="birthCountry" />}
											onChange={handleChange}
											value={toFirstUpper(decuius.statoNascita)}
											defaultValue="Italia"
											sx={{ color: 'white' }}
										>
											{
												countryOptions
													.slice()
													.map((countryOption) => (
														<MenuItem key={countryOption.name} value={countryOption.name}>
															{countryOption.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || !isNotNullOrUndefined(decuius.statoNascita)}
									>
										<InputLabel> <FormattedMessage id="birthRegion" /> </InputLabel>
										<Select
											label={<FormattedMessage id="birthRegion" />}
											onChange={(e: any) => setSelectedBirthRegion(e.target.value)}
											sx={{ color: 'white' }}
											value={selectedBirthRegion}
										>
											{
												regions && regions
													.slice()
													.map((regione) => (
														<MenuItem key={regione.id} value={regione.id}>
															{regione.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || selectedBirthRegion === ""}
									>
										<InputLabel> <FormattedMessage id="birthProvince" /> </InputLabel>
										<Select
											label={<FormattedMessage id="birthProvince" />}
											onChange={(e: any) => {
												setDecuius((currentFormData: any) => ({ ...currentFormData, provinciaNascita: birthProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
												setSelectedBirthProvince(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedBirthProvince}
										>
											{
												birthProvinces && birthProvinces
													.slice()
													.map((provincia: Provincia) => (
														<MenuItem key={provincia.id} value={provincia.id}>
															{provincia.sigla}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || selectedBirthProvince === ""}
									>
										<InputLabel> <FormattedMessage id="birthCity" /> </InputLabel>
										<Select
											name="comuneNascita"
											label={<FormattedMessage id="birthCity" />}
											onChange={(e: any) => {
												setDecuius((currentFormData: any) => ({ ...currentFormData, comuneNascita: birthCities.find((city) => city.id == e.target.value)?.nome ?? '' }))
												setSelectedBirthCity(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedBirthCity}
										>
											{
												birthCities && birthCities
													.slice()
													.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth size='small'>
										<InputLabel> <FormattedMessage id="deathCountry" /> </InputLabel>
										<Select
											name="statoMorte"
											label={<FormattedMessage id="deathCountry" />}
											onChange={handleChange}
											value={toFirstUpper(decuius.statoMorte)}
											defaultValue="Italia"
											sx={{ color: 'white' }}
										>
											{
												countryOptions
													.slice()
													.map((countryOption) => (
														<MenuItem key={countryOption.name} value={countryOption.name}>
															{countryOption.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableDeathAdditionalFields || !isNotNullOrUndefined(decuius.statoMorte)}
									>
										<InputLabel> <FormattedMessage id="deathRegion" /> </InputLabel>
										<Select
											label={<FormattedMessage id="deathRegion" />}
											onChange={(e: any) => setSelectedDeathRegion(e.target.value)}
											sx={{ color: 'white' }}
											value={selectedDeathRegion}
										>
											{
												regions && regions
													.slice()
													.map((regione) => (
														<MenuItem key={regione.id} value={regione.id}>
															{regione.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableDeathAdditionalFields || selectedDeathRegion === ""}
									>
										<InputLabel> <FormattedMessage id="deathProvince" /> </InputLabel>
										<Select
											label={<FormattedMessage id="deathProvince" />}
											onChange={(e: any) => {
												setDecuius((currentFormData: any) => ({ ...currentFormData, provinciaMorte: deathProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
												setSelectedDeathProvince(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedDeathProvince}
										>
											{
												deathProvinces && deathProvinces
													.slice()
													.map((provincia: Provincia) => (
														<MenuItem key={provincia.id} value={provincia.id}>
															{provincia.sigla}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableDeathAdditionalFields || selectedDeathProvince === ""}
									>
										<InputLabel> <FormattedMessage id="deathMunicipality" /> </InputLabel>
										<Select
											name="comune"
											label={<FormattedMessage id="deathMunicipality" />}
											onChange={(e: any) => {
												setDecuius((currentFormData: any) => ({ ...currentFormData, comuneMorte: deathCities.find((city) => city.id == e.target.value)?.nome ?? '' }))
												setSelectedDeathCity(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedDeathCity}
										>
											{
												deathCities && deathCities
													.slice()
													.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth size='small'>
										<InputLabel> <FormattedMessage id="residenceCountry" /> </InputLabel>
										<Select
											name="nazioneResidenza"
											label={<FormattedMessage id="residenceCountry" />}
											onChange={handleChange}
											value={toFirstUpper(decuius.nazioneResidenza)}
											defaultValue="Italia"
											sx={{ color: 'white' }}
										>
											{
												countryOptions
													.slice()
													.map((countryOption) => (
														<MenuItem key={countryOption.name} value={countryOption.name}>
															{countryOption.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || !isNotNullOrUndefined(decuius.nazioneResidenza)}
									>
										<InputLabel> <FormattedMessage id="residenceRegion" /> </InputLabel>
										<Select
											label={<FormattedMessage id="residenceRegion" />}
											onChange={(e: any) => setSelectedResidenceRegion(e.target.value)}
											sx={{ color: 'white' }}
											value={selectedResidenceRegion}
										>
											{
												regions && regions
													.slice()
													.map((regione) => (
														<MenuItem key={regione.id} value={regione.id}>
															{regione.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || selectedResidenceRegion === ""}
									>
										<InputLabel> <FormattedMessage id="residenceProvince" /> </InputLabel>
										<Select
											label={<FormattedMessage id="residenceProvince" />}
											onChange={(e: any) => {
												setDecuius((currentFormData: any) => ({ ...currentFormData, provinciaResidenza: residenceProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
												setSelectedResidenceProvince(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedResidenceProvince}
										>
											{
												residenceProvinces && residenceProvinces
													.slice()
													.map((provincia: Provincia) => (
														<MenuItem key={provincia.id} value={provincia.id}>
															{provincia.sigla}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || selectedResidenceProvince === ""}
									>
										<InputLabel> <FormattedMessage id="residenceMunicipality" /> </InputLabel>
										<Select
											name="comuneResidenza"
											label={<FormattedMessage id="residenceMunicipality" />}
											onChange={(e: any) => {
												const city = residenceCities.find((city) => city.id == e.target.value);
												setDecuius((currentFormData: any) => ({ ...currentFormData, comuneResidenza: city?.nome ?? '', capResidenza: city?.cap ?? '' }))
												setSelectedResidenceCity(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedResidenceCity}
										>
											{
												residenceCities && residenceCities
													.slice()
													.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="fiscalCode" />}
										name="codiceFiscale"
										onChange={handleChange}
										value={decuius.codiceFiscale}
										InputLabelProps={{ shrink: isNotNullOrUndefined(decuius.codiceFiscale) }}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="address" />}
										name="indirizzoResidenza"
										onChange={handleChange}
										value={decuius.indirizzoResidenza}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="civic" />}
										name="civicoResidenza"
										onChange={handleChange}
										value={decuius.civicoResidenza}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="cap" />}
										name="capResidenza"
										onChange={handleChange}
										value={decuius.capResidenza}
										InputLabelProps={{
											shrink: isNotNullOrUndefined(decuius.capResidenza)
										}}
									/>
								</Grid>

								<Grid
									item
									lg={12}
									md={12}
									xs={12}
								>
									<TextField
										multiline
										fullWidth
										size='small'
										label={<FormattedMessage id="notes" />}
										name="note"
										inputProps={{
											style: {
												height: 50
											}
										}}
										onChange={handleChange}
										value={decuius.note}
									/>
								</Grid>
							</Grid>

							{
								!isAdd &&
								<Grid container>
									<Dialog
										fullWidth maxWidth="xl"
										open={transferDecuiusDialogOpen}
										onClose={() => setTransferDecuiusDialogOpen(false)}
										aria-labelledby="transfer-decuius-modal-title"
										aria-describedby="transfer-decuius-modal-description"
									>
										<DialogTitle sx={{ m: 0, p: 2 }} id="transfer-decuius-dialog-title">
											<FormattedMessage id="transferDecuius" />
										</DialogTitle>
										<IconButton
											aria-label="close"
											onClick={() => setTransferDecuiusDialogOpen(false)}
											sx={(theme) => ({
												position: 'absolute',
												right: 8,
												top: 8,
												color: theme.palette.grey[500],
											})}
										>
											<CloseIcon />
										</IconButton>
										<DialogContent>
											<Grid container direction="row" spacing={2}>
												<Grid
													item
													lg={3}
													md={3}
													xs={12}
												>
													<Autocomplete
														id="structure"
														options={structuresShortInfoOptions}
														renderOption={(props, option) => {
															return (
																<li {...props} key={option.id}>
																	{option.label}
																</li>
															);
														}} renderInput={(params) =>
															<TextField {...params} name="structure"
																fullWidth label={<FormattedMessage id="structure" />} size='small'
															/>}
														onChange={(e: any, value: IAutocompleteOptions | null) => {
															handleStructureAutocompleteChange(value);
														}}
														onOpen={() => { setStructureAutocompleteOpen(true) }}
														onClose={() => { setStructureAutocompleteOpen(false) }}
														open={structureAutocompleteOpen}
														value={chosenStructure}
														isOptionEqualToValue={(option, value) => option.id === value.id}
													/>
												</Grid>
												<Grid
													item
													lg={3}
													md={3}
													xs={12}
												>
													<Autocomplete
														id="slat"
														options={steccheByStrutturaOptions}
														renderOption={(props, option) => {
															return (
																<li {...props} key={option.id}>
																	{option.label}
																</li>
															);
														}} renderInput={(params) =>
															<TextField {...params} name="slat"
																fullWidth label={<FormattedMessage id="slat" />} size='small'
															/>}
														onChange={(e: any, value: IAutocompleteOptions | null) => {
															handleSteccaAutocompleteChange(value);
														}}
														onOpen={() => { setSteccaAutocompleteOpen(true) }}
														onClose={() => { setSteccaAutocompleteOpen(false) }}
														open={steccaAutocompleteOpen}
														value={chosenStecca}
														isOptionEqualToValue={(option, value) => option.id === value.id}
													/>
												</Grid>
												<Grid
													item
													lg={3}
													md={3}
													xs={12}
												>
													<Autocomplete
														id="place"
														options={placesOptions}
														renderOption={(props, option) => {
															return (
																<li {...props} key={option.id}>
																	{option.label}
																</li>
															);
														}} renderInput={(params) =>
															<TextField {...params} name="place"
																fullWidth label={<FormattedMessage id="place" />} size='small'
															/>}
														onChange={(e: any, value: IAutocompleteOptions | null) => {
															handlePlacesAutocompleteChange(value);
														}}
														onOpen={() => { setPlacesAutocompleteOpen(true) }}
														onClose={() => { setPlacesAutocompleteOpen(false) }}
														open={placesAutocompleteOpen}
														value={chosenPlace}
														isOptionEqualToValue={(option, value) => option.id === value.id}
													/>
												</Grid>
												<Grid
													item
													lg={3}
													md={3}
													xs={12}
												>
													<Button variant="contained" onClick={transferDecuius} disabled={chosenPlace == null}><FormattedMessage id="transferHere" /></Button>
												</Grid>
											</Grid>
										</DialogContent>
									</Dialog>
									<Grid
										item
										lg={12}
										md={12}
										xs={12}
									>
										<Accordion sx={{ mb: 1 }} defaultExpanded>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="decuiusPlaceInfo"
												id="decuiusPlaceInfo"
											>
												<Typography> <FormattedMessage id="showPlaceInfo" /> </Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container direction="row" columnSpacing={1} sx={{ mb: 1, alignItems: 'center' }}>
													<Grid item>
														<Typography variant="h5" align="center">
															<FormattedMessage id="structure" />:
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="body1" align="center">
															{isNotNullOrUndefined(decuiusPlaceInfo?.structureName) ? decuiusPlaceInfo?.structureName : 'N/A'}
														</Typography>
													</Grid>

													<Grid item>
														<Typography variant="h5" align="center">
															<FormattedMessage id="slat" />:
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="body1" align="center">
															{isNotNullOrUndefined(decuiusPlaceInfo?.slatName) ? decuiusPlaceInfo?.slatName : 'N/A'}
														</Typography>
													</Grid>

													<Grid item>
														<Typography variant="h5" align="center">
															<FormattedMessage id="placeNumber" />:
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="body1" align="center">
															{isNotNullOrUndefined(decuiusPlaceInfo?.placeNumber) ? decuiusPlaceInfo?.placeNumber : 'N/A'}
														</Typography>
													</Grid>
													<Grid item sx={{ marginX: 2 }}>
														<Button variant="contained" onClick={() => setTransferDecuiusDialogOpen(true)}> <FormattedMessage id="transferDecuius" /> </Button>
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>
									</Grid>
								</Grid>
							}

							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									{!isAdd && (
										<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
											<Button
												color="error"
												type="button"
												variant="contained"
												onClick={() => setDeleteDecuiusDialogOpen(true)}
												startIcon={<DeleteIcon />}
											>
												<FormattedMessage id="delete" />
											</Button>
											<Button
												variant="contained"
												startIcon={<IconFiles />}
												component={RouterLink}
												to={`/decuius/dossiersList?decuiusId=${decuius.id}`}
											>
												<FormattedMessage id="goToDossiers" />
											</Button>
										</Box>
									)}
								</Grid>
								<Grid item xs={12} md={6}>
									<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
										<Button
											color="primary"
											type="button"
											variant="contained"
											onClick={decuiusUpsert}
											startIcon={<SaveIcon />}
										>
											<FormattedMessage id="save" />
										</Button>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			<Divider />
		</Card>
	)
};

export default DecuiusUpsert;
