import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Allegato } from "types/allegato";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { getShortDate } from "utils/common";
import DownloadIcon from '@mui/icons-material/Download';

interface AttachmentsListProps {
    files: Allegato[];
    downloadAllegato: (file: any) => void;
}

const AttachmentsList: FC<AttachmentsListProps> = (props) => {
    const { files, downloadAllegato } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left"> <FormattedMessage id="attachmentName" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="dateOfInsert" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    files &&
                    files.length > 0 &&
                    files.map((file: Allegato) => (
                        <AlternateTableRow>
                            <TableCell align="left">{file.nomeFile}</TableCell>
                            <TableCell align="left">{getShortDate(file.dateOfInsert)}</TableCell>
                            <TableCell align="left">

                                <Tooltip title={<FormattedMessage id="download" />} aria-label="Download">
                                    <IconButton onClick={() => downloadAllegato(file)}>
                                        <DownloadIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </AlternateTableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
}

export default AttachmentsList;