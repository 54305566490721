import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { Pratica } from "types/pratica";
import { SerieNumeratore } from "types/serieNumeratore";
import { getFormValue, isNotNullOrUndefined } from "utils/common";

interface InitialDataStepProps {
    pratica: Pratica;
    handleChangePratica: (e: any) => void;
    handleDateChangePratica: (fieldName: string, date: any) => void;
    updateDossierNumber?: (number: string) => void;
}

const InitialDataStep: FC<InitialDataStepProps> = (props) => {
    const { pratica, handleChangePratica, handleDateChangePratica, updateDossierNumber } = props;

    const praticaService = new PraticaService();

    const [serieNumeratori, setSerieNumeratori] = useState<Array<SerieNumeratore>>([]);

    const [numberShrink, setNumberShrink] = useState<boolean>(false);

    useEffect(() => {
        setNumberShrink(isNotNullOrUndefined(pratica.numero));
    }, [pratica.numero])

    useEffect(() => {
        (async () => {
            setSerieNumeratori(await praticaService.GetUnpaginatedPraticaSerieNumeratori());
        })();
    }, [])

    return (
        <Grid container direction="row" spacing={1}>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <FormControl fullWidth required size='small'>
                    <InputLabel> <FormattedMessage id="series" /> </InputLabel>
                    <Select
                        name="numeratoreId"
                        label={<FormattedMessage id="series" />}
                        onChange={(e) => {
                            handleChangePratica(e);
                            if (isNotNullOrUndefined(e.target.value) && isNotNullOrUndefined(updateDossierNumber)) {
                                const serie: SerieNumeratore = serieNumeratori.find((serieNumeratore: SerieNumeratore) => serieNumeratore.id == e.target.value)!;

                                updateDossierNumber!(String(serie.numero + 1));
                            }
                        }}
                        sx={{ color: 'white' }}
                        value={pratica.numeratoreId}
                    >
                        {
                            serieNumeratori.length > 0 && serieNumeratori
                                .slice()
                                .map((serie: SerieNumeratore) => (
                                    <MenuItem key={serie.id} value={serie.id}>
                                        {serie.nome} - {serie.numero}
                                    </MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="number" />}
                    name="numero"
                    required
                    onChange={handleChangePratica}
                    value={pratica.numero}
                    InputLabelProps={{ shrink: numberShrink }}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <DatePicker
                    inputFormat="DD/MM/YYYY"
                    label={<FormattedMessage id="date" />}
                    onChange={(date: any) => {
                        handleDateChangePratica('data', date);
                    }}
                    renderInput={(params) => (
                        <TextField fullWidth {...params} error={false} size='small' />
                    )}
                    value={pratica.data ?? ''}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="preliminaryDate" />}
                    name="dataPreliminare"
                    onChange={handleChangePratica}
                    value={pratica.dataPreliminare}
                />
            </Grid>
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
            >
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="notes" />}
                    name="note"
                    inputProps={{
                        style: {
                            height: 50
                        }
                    }}
                    onChange={handleChangePratica}
                    value={pratica.note}
                />
            </Grid>
        </Grid>
    )
}

export default InitialDataStep;