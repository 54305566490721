import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, Button, TableCell, Collapse, Checkbox, FormControlLabel, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, FormControl, Radio, RadioGroup, DialogActions, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { Fattura } from "types/fattura";
import AlternateTableRow from "ui-component/AlternateTableRow";
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import { composeNotistackMessage, getCurrencyByNumber, getShortDate, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import { dispatch } from "store";
import { setInvoicesServicesIds } from "store/slices/invoicesServicesIdsSlice";
import { setInvoicesPaymentsIds } from "store/slices/invoicesPaymentsIdsSlice";
import { useNavigate } from "react-router-dom";
import TableRowsLoader from "ui-component/TableRowsLoader";
import DownloadIcon from '@mui/icons-material/Download';
import { FatturaService } from "services/FatturaServices";
import { Pagamento } from "types/pagamento";
import { TipoPagamento } from "types/enums/TipoPagamento";
import { Close, DeleteTwoTone } from "@mui/icons-material";
import { PlaceServiceToInvoice } from "types/placeServiceToInvoice";
import SendIcon from '@mui/icons-material/Send';
import BackdropLoader from "ui-component/BackdropLoader";
import AddIcon from '@mui/icons-material/Add';

interface InvoicesStepProps {
    praticaId: string;
}

const InvoicesStep: FC<InvoicesStepProps> = (props) => {
    const { praticaId } = props;

    const praticaService = new PraticaService();
    const fatturaService = new FatturaService();

    const intl = useIntl();

    const [fatture, setFatture] = useState<Array<Fattura>>([]);
    const [serviziPosti, setServiziPosti] = useState<Array<PlaceServiceToInvoice>>([]);

    const navigate = useNavigate();

    const [openAddInvoiceCollapse, setOpenAddInvoiceCollapse] = useState<boolean>(false);

    const [isInvoicesLoading, setIsInvoicesLoading] = useState<boolean>(false);
    const [isLoadingEInvoiceSend, setIsLoadingEInvoiceSend] = useState<boolean>(false);

    useEffect(() => {
        setIsInvoicesLoading(true);

        (async () => {
            await retrievFatture();

            const retrievedServiziPosti: Array<PlaceServiceToInvoice> = await praticaService.GetServicesPlacesToInvoice(praticaId);
            setServiziPosti(retrievedServiziPosti);

            setIsInvoicesLoading(false);
        })()

    }, [])

    const retrievFatture = async () => {
        const retrievedFatture: Array<Fattura> = await praticaService.GetPraticaFatture(praticaId);
        setFatture(retrievedFatture);
    }

    const invoiceCheckedServices = () => {
        dispatch(setInvoicesServicesIds(checkedServicesIds));
        dispatch(setInvoicesPaymentsIds(checkedPaymentsIds));
        navigate(`/invoices/create?dossierId=${praticaId}`);
    }

    const downloadFattura = async (fatturaId: string, fatturaName: string) => {
        const fatturaBase64: string = await fatturaService.DownloadFatturaById(fatturaId);
        handleDownloadFile(fatturaBase64, fatturaName);
    }

    const [checkedPaymentsIds, setCheckedPaymentsIds] = useState<Array<string>>([]);
    const [checkedServicesIds, setCheckedServicesIds] = useState<Array<string>>([]);

    const checkPayment = (e: any, postoServizioId: string) => {
        let newCheckedServices: Array<string> = [];
        newCheckedServices = e.target.checked ? [...checkedPaymentsIds, postoServizioId] : checkedPaymentsIds.filter((checkedService) => checkedService !== postoServizioId);
        setCheckedPaymentsIds(newCheckedServices);
    }

    const checkService = () => {
        let newCheckedServicesIds: Array<string> = [];
        newCheckedServicesIds = checkedServicesIds.includes(placeServiceToChooseInvoice!.postoServizioId!) ? checkedServicesIds : [...checkedServicesIds, placeServiceToChooseInvoice!.postoServizioId!];
        setCheckedServicesIds(newCheckedServicesIds);
        removePlaceServicePayments();
    }

    const [whatToInvoiceDialogOpen, setWhatToInvoiceDialogOpen] = useState<boolean>(false);
    const [placeServiceToChooseInvoice, setPlaceServiceToChooseInvoice] = useState<PlaceServiceToInvoice | null>(null);

    const [whatToInvoiceRadioValue, setWhatToInvoiceRadioValue] = useState<number>(2);

    const handleWhatToInvoiceRadioChange = (e: any) => {
        const value = e.target.value;
        setWhatToInvoiceRadioValue(value);

        if (value == 0 || value == 2) {
            let newCheckedServices = checkedServicesIds.filter((checkedServiceId) => checkedServiceId !== placeServiceToChooseInvoice!.postoServizioId);
            setCheckedServicesIds(newCheckedServices);
            if (value == 2) {
                removePlaceServicePayments();
            }
        } else {
            checkService();
        }
    }

    const removePlaceServicePayments = () => {
        let newCheckedPayments = checkedPaymentsIds.filter((checkedPaymentId) =>
            !placeServiceToChooseInvoice?.pagamentiAssociati?.find((pagamento) => pagamento.id === checkedPaymentId)
        );
        setCheckedPaymentsIds(newCheckedPayments);
    }

    const handleSetWhatToInvoiceRadioValue = (placeService: PlaceServiceToInvoice) => {
        const areTherePlaceServicePayments: boolean = checkedPaymentsIds.find((checkedPaymentId) => placeService.pagamentiAssociati?.find((pagamento) => pagamento.id == checkedPaymentId)) !== undefined;

        if (areTherePlaceServicePayments) {
            setWhatToInvoiceRadioValue(0);
        } else if (checkedServicesIds.includes(placeService.postoServizioId)) {
            setWhatToInvoiceRadioValue(1);
        } else {
            setWhatToInvoiceRadioValue(2);
        }
    }

    const sendEInvoice = async () => {
        setIsLoadingEInvoiceSend(true);

        try {
            await fatturaService.SendElectronicInvoice(eInvoiceToSendData!.id);
            composeNotistackMessage(intl.formatMessage({ id: 'invoiceSuccessfullySent' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingEInvoiceSend(false);
        }

        await retrievFatture();
    }

    const [openSendEInvoiceDialog, setOpenSendEInvoiceDialog] = useState<boolean>(false);
    const [eInvoiceToSendData, setEInvoiceToSendData] = useState<{ id: string, invoiceNumber: string } | null>(null);

    const handleEInvoiceToSend = async (invoiceId: string, invoiceNumber: string) => {
        setEInvoiceToSendData({
            id: invoiceId,
            invoiceNumber: invoiceNumber
        });

        setOpenSendEInvoiceDialog(true);
    }

    return (
        <>
            <BackdropLoader open={isLoadingEInvoiceSend} />

            <Dialog
                fullWidth maxWidth="sm"
                open={whatToInvoiceDialogOpen}
                onClose={() => {
                    setWhatToInvoiceDialogOpen(false);
                    setPlaceServiceToChooseInvoice(null);
                }}
                aria-labelledby="choose-what-to-invoice-modal-title"
                aria-describedby="choose-what-to-invoice-modal-description"
            >
                <DialogTitle id="choose-what-to-invoice-title">
                    <FormattedMessage id="chooseWhatToInvoiceOfTheService" />: {placeServiceToChooseInvoice?.descrizione}
                    <IconButton aria-label="close" onClick={() => setWhatToInvoiceDialogOpen(false)} style={{ position: 'absolute', right: '5px', top: '4px' }}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography fontSize={15} color='orangered' fontStyle='italic'>
                        <FormattedMessage id="cantInvoicePlaceServiceIfAnnualService" />!
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            row={false}
                            aria-labelledby="what-to-invoice"
                            name="what-to-invoice-radio"
                            value={whatToInvoiceRadioValue}
                            onChange={handleWhatToInvoiceRadioChange}
                        >
                            <FormControlLabel value={2} control={<Radio />} label={<FormattedMessage id="nothing" />} />
                            <FormControlLabel value={1} control={<Radio />} label={<FormattedMessage id="entireService" />} disabled={!placeServiceToChooseInvoice?.allowFullServiceInvoice} />
                            <FormControlLabel value={0} control={<Radio />} label={<FormattedMessage id="somePayments" />} disabled={placeServiceToChooseInvoice?.pagamentiAssociati == null || placeServiceToChooseInvoice?.pagamentiAssociati.length < 1 || placeServiceToChooseInvoice?.pagamentiAssociati == undefined} />
                            <TableContainer component={Paper} sx={{ mb: 4 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <PurpleStyledTableCell></PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="date" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> (€) </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="paymentMethod" /> </PurpleStyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            isNotNullOrUndefined(placeServiceToChooseInvoice?.pagamentiAssociati) &&
                                            placeServiceToChooseInvoice?.pagamentiAssociati?.length != 0 &&
                                            placeServiceToChooseInvoice?.pagamentiAssociati?.map((postoServizioPayment: Pagamento) => (
                                                <AlternateTableRow key={postoServizioPayment.id}>
                                                    <TableCell>
                                                        <Checkbox key={`check-service-${postoServizioPayment.id}`}
                                                            checked={checkedPaymentsIds.includes(postoServizioPayment.id!)}
                                                            onChange={(e: any) => checkPayment(e, postoServizioPayment.id!)}
                                                            disabled={whatToInvoiceRadioValue == 2 || whatToInvoiceRadioValue == 1}
                                                        />
                                                    </TableCell>
                                                    <TableCell> {getShortDate(postoServizioPayment.data)} </TableCell>
                                                    <TableCell> {postoServizioPayment.descrizione} </TableCell>
                                                    <TableCell align='right'> {getCurrencyByNumber(postoServizioPayment.importo)} </TableCell>
                                                    <TableCell> {TipoPagamento[postoServizioPayment.mezzo]} </TableCell>
                                                </AlternateTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openSendEInvoiceDialog}
                onClose={() => setOpenSendEInvoiceDialog(false)}
                aria-labelledby="alert-send-einvoice-title"
                aria-describedby="alert-send-einvoice-description"
            >
                <DialogTitle id="send-einvoice-title">
                    <FormattedMessage id="confirmSendEinvoice" />: <b>{eInvoiceToSendData?.invoiceNumber}</b>?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenSendEInvoiceDialog(false);
                        setEInvoiceToSendData(null);
                    }} variant="outlined" color="info"> <FormattedMessage id="cancel" /> </Button>
                    <Button onClick={() => {
                        setOpenSendEInvoiceDialog(false);
                        setEInvoiceToSendData(null);
                        sendEInvoice();
                    }} variant="contained" color="info"><FormattedMessage id="send" /></Button>
                </DialogActions>
            </Dialog>
            <Button sx={{ mb: 3 }} variant="contained" onClick={() => setOpenAddInvoiceCollapse(!openAddInvoiceCollapse)} startIcon={<AddIcon />}>
                <FormattedMessage id="addInvoice" />
            </Button>
            <Collapse in={openAddInvoiceCollapse}>
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <PurpleStyledTableCell> <FormattedMessage id="placeNumber" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell> <FormattedMessage id="date" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell align="right"> <FormattedMessage id="quantity" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell align="right"> <FormattedMessage id="totalAmount" /> </PurpleStyledTableCell>
                                <PurpleStyledTableCell align="right"> <FormattedMessage id="payed" /> </PurpleStyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                serviziPosti !== null &&
                                serviziPosti.map((placeService: PlaceServiceToInvoice, index: number) => (
                                    <AlternateTableRow key={placeService.postoServizioId} sx={{
                                        '&:hover': {
                                            backgroundColor: 'lightgray',
                                            cursor: placeService.allowInvoice ? 'pointer' : 'not-allowed'
                                        },
                                        backgroundColor: index % 2 === 0 ? '#F0F0F0' : '#FFFFFF'
                                    }}
                                        onClick={() => {
                                            if (placeService.allowInvoice) {
                                                setPlaceServiceToChooseInvoice(placeService);
                                                setWhatToInvoiceDialogOpen(true);
                                                handleSetWhatToInvoiceRadioValue(placeService);
                                            }
                                        }}
                                    >
                                        <TableCell> {placeService.numeroPosto} </TableCell>
                                        <TableCell> {placeService.descrizione} </TableCell>
                                        <TableCell> {getShortDate(placeService.data)} </TableCell>
                                        <TableCell align='right'> {getCurrencyByNumber(placeService.importo)} </TableCell>
                                        <TableCell align='right'> {placeService.quantita} </TableCell>
                                        <TableCell align='right'> {getCurrencyByNumber(placeService.importo * placeService.quantita)} </TableCell>
                                        <TableCell align='right'> {getCurrencyByNumber(placeService.pagato)} </TableCell>
                                    </AlternateTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button sx={{ mb: 3 }} variant="contained" onClick={invoiceCheckedServices} disabled={checkedPaymentsIds.length === 0 && checkedServicesIds.length === 0}>
                    <FormattedMessage id="issueInvoice" />
                </Button>
            </Collapse>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <PurpleStyledTableCell> <FormattedMessage id="invoiceNumber" /> </PurpleStyledTableCell>
                            <PurpleStyledTableCell> <FormattedMessage id="date" /> </PurpleStyledTableCell>
                            <PurpleStyledTableCell align="right"> <FormattedMessage id="totalAmount" /> </PurpleStyledTableCell>
                            <PurpleStyledTableCell align="right"> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isInvoicesLoading ?
                                <TableRowsLoader rowsNum={4} colsNum={3} /> :
                                fatture.map((fattura: Fattura) => (
                                    <AlternateTableRow>
                                        <TableCell>
                                            {fattura.numero}
                                        </TableCell>
                                        <TableCell>
                                            {getShortDate(fattura.data)}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {getCurrencyByNumber(fattura.importoTotale!)}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={<FormattedMessage id="download" />} aria-label="Download">
                                                <IconButton onClick={() => downloadFattura(fattura.id!, `invoice-${fattura.numero}-${fattura.nome}_${fattura.cognome}.pdf`)}>
                                                    <DownloadIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={<FormattedMessage id="sendElectronicInvoice" />} aria-label="send">
                                                <IconButton onClick={() => handleEInvoiceToSend(fattura.id!, fattura!.numero!)}>
                                                    <SendIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

}

export default InvoicesStep;