import { Autocomplete, Button, Card, CardContent, CardHeader, Grid, IconButton, TablePagination, TextField, Typography, debounce, useMediaQuery, useTheme } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ServizioService } from "services/ServizioServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { ServiceReport } from "types/serviceReport";
import ClearIcon from '@mui/icons-material/Clear';
import ServiziReportPage from "./ServiziReportPage";
import ServiziReportPageMobile from "./ServiziReportPageMobile";
import IAutocompleteOptions from "interfaces/IAutocompleteOptions";
import { Servizio } from "types/servizio";
import { Box } from "@mui/system";
import { isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import { useSelector } from "store";

interface ServiziReportFilters {
    startDate: string | null,
    endDate: string | null,
    servicesIds: Array<string>
}

const ServiziReport = () => {
    const intl = useIntl();

    const servizioService = new ServizioService();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<ServiziReportFilters>({
        startDate: null,
        endDate: null,
        servicesIds: []
    });

    const [serviziReport, setServiziReport] = useState<PaginationResult<ServiceReport>>(voidPagination);

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'reportServicesListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const updateServiziReport = () => {
        setIsPageLoading(true);
        getServiziReport(paginationQuery, filters, chosenServices);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'reportServicesListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getServiziReport = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: ServiziReportFilters, chosenServices: Array<IAutocompleteOptions>) => {
        const allFilters = { ...filters, servicesIds: chosenServices.map((chosenService) => chosenService.id) };

        const retrievedServizi = await servizioService.GetServiziReport(paginationQuery, allFilters);
        setServiziReport(retrievedServizi);

        const retrievedServices = await servizioService.GetUnpaginatedServizi();
        setServicesOptions(retrievedServices.map((service: Servizio) => ({ id: service.id!, label: service.descrizione })));

        setIsPageLoading(false);
    }, 700), []);

    const [servicesAutocompleteOpen, setServicesAutocompleteOpen] = useState<boolean>(false);

    const [servicesOptions, setServicesOptions] = useState<Array<IAutocompleteOptions>>([]);

    const [chosenServices, setChosenServices] = useState<Array<IAutocompleteOptions>>([]);

    const handleAutocompleteChange = (value: Array<IAutocompleteOptions>) => {
        setChosenServices(value);
    }

    useEffect(() => {
        updateServiziReport();
    }, [paginationQuery, filters, chosenServices]);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const handleDateFiltersChange = (e: any, fieldName: string) => {
        setFilters((currentFormData: any) => ({ ...currentFormData, [fieldName]: e == null ? e : e.format() }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const emptyFilters = () => {
        setFilters({
            startDate: null,
            endDate: null,
            servicesIds: []
        });

        setChosenServices([]);
    }

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <MobileDatePicker
                        label={<FormattedMessage id="startDate" />}
                        onChange={(e: any) => handleDateFiltersChange(e, 'startDate')}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" name="startDate" />}
                        InputProps={{
                            endAdornment: (
                                filters.startDate !== null &&
                                <IconButton onClick={() => handleDateFiltersChange(null, 'startDate')} edge="end" size="small">
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        value={filters.startDate}
                        inputFormat="DD/MM/YYYY"
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <MobileDatePicker
                        label={<FormattedMessage id="endDate" />}
                        onChange={(e: any) => handleDateFiltersChange(e, 'endDate')}
                        renderInput={(params) =>
                            <TextField {...params}
                                fullWidth
                                size="small"
                                name="endDate"
                                InputProps={{
                                    endAdornment: (
                                        filters.endDate !== null &&
                                        <IconButton onClick={() => handleDateFiltersChange(null, 'endDate')} edge="end" size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    ),
                                }}
                            />}
                        value={filters.endDate}
                        inputFormat="DD/MM/YYYY"
                    />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Autocomplete
                        id="services"
                        options={servicesOptions}
                        renderInput={(params) =>
                            <TextField {...params} name="services"
                                fullWidth label={<FormattedMessage id="services" />} size='small'
                            />}
                        onChange={(e: any, value: IAutocompleteOptions[]) => {
                            handleAutocompleteChange(value);
                        }}
                        onOpen={() => { setServicesAutocompleteOpen(true) }}
                        onClose={() => { setServicesAutocompleteOpen(false) }}
                        open={servicesAutocompleteOpen}
                        value={chosenServices}
                        multiple={true}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Button variant="contained" onClick={emptyFilters}> <FormattedMessage id="emptyFilters" /> </Button>
                </Grid>
            </Grid>
            <Card>
                <CardHeader title={<FormattedMessage id="servicesReport" />} />
                <CardContent>
                    <Box width="100%" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography variant="body2"><i>* <FormattedMessage id="shownPricesIncludeVAT" /></i></Typography>
                    </Box>
                    {
                        mobileDevice ?
                            <ServiziReportPageMobile
                                serviziReport={serviziReport}
                                isPageLoading={isPageLoading}
                            /> :
                            <ServiziReportPage
                                serviziReport={serviziReport}
                                isPageLoading={isPageLoading}

                                setSortOptions={setSortOptions}
                                sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            />
                    }

                    <TablePagination
                        component="div"
                        count={serviziReport?.totalCount}
                        onRowsPerPageChange={(e) => {
                            setPageSize(parseInt(e.target.value, 10));
                        }}
                        onPageChange={(e, page) => {
                            setPageNumber(page);
                        }}
                        page={paginationQuery.pageNumber}
                        rowsPerPage={paginationQuery.pageSize}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                    />
                </CardContent>
            </Card>
        </>
    );
}

export default ServiziReport;