import { Grid, IconButton, Table, TableHead, TableBody, TableCell, TableRow, Tooltip, FormControlLabel, Checkbox, TableContainer } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PaymentToDo } from "types/paymentToDo";
import { PaginationResult } from "types/paginationResult";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { Link as RouterLink } from 'react-router-dom';
import { IconFiles } from '@tabler/icons';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { CommunicationType } from "types/enums/CommunicationType";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { getCurrencyByNumber } from "utils/common";
import TableCellWithSorting from "ui-component/TableCellWithSorting";
import ISortOptions from "interfaces/ISortOptions";

interface PaymentsToDoProps {
    paymentsToDo: PaginationResult<PaymentToDo>;
    checkAllPaymentsToDo: (e: any) => void;
    isPaymentToDoChecked: (paymentToDo: PaymentToDo) => boolean;
    checkPaymentToDo: (e: any, paymentToDo: PaymentToDo) => void;
    updateEntityToSendCommunication: (paymentToDo: PaymentToDo, communicationType: CommunicationType) => void;
    updateEntityToDownloadBulletin: (paymentToDo: PaymentToDo) => void;
    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
}

const PaymentsToDoPage: FC<PaymentsToDoProps> = (props) => {
    const { paymentsToDo, checkAllPaymentsToDo, isPaymentToDoChecked, checkPaymentToDo, updateEntityToSendCommunication, updateEntityToDownloadBulletin, setSortOptions, sortOptions } = props;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<b><FormattedMessage id="all" /></b>}
                                labelPlacement="end"
                                onChange={(e: any) => {
                                    checkAllPaymentsToDo(e);
                                }}
                            />
                        </TableCell>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="payerFullName"
                            title={<FormattedMessage id="name" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="placeServiceDescription"
                            title={<FormattedMessage id="description" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="placeServiceBankTransferPaymentCode"
                            title={<FormattedMessage id="code" />}
                        />
                        <TableCell><FormattedMessage id="serviceType" /></TableCell>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="expireDate"
                            title={<FormattedMessage id="expireDate" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="year"
                            title={<FormattedMessage id="year" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="amountToPay"
                            title={<FormattedMessage id="toPay" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="totalAmount"
                            title={<FormattedMessage id="totalAmount" />}
                        />
                        <TableCell><FormattedMessage id="lastNotification" /></TableCell>
                        <TableCell><FormattedMessage id="actions" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        paymentsToDo.items.map((paymentToDo: PaymentToDo, index: number) => (
                            <AlternateTableRow key={index}>
                                <TableCell>
                                    <Checkbox key={`check-payment-to-do-${index}`}
                                        checked={isPaymentToDoChecked(paymentToDo)}
                                        onChange={(e: any) => checkPaymentToDo(e, paymentToDo)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentToDo.payerFullName} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentToDo.placeServiceDescription} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentToDo.placeServiceBankTransferPaymentCode} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> <FormattedMessage id={paymentToDo.annual ? 'annual' : 'unaTantum'} /> </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {new Date(paymentToDo.expireDate).toLocaleDateString('it-IT', {
                                        day: '2-digit',
                                        month: '2-digit'
                                    })} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentToDo.year} </Grid>
                                </TableCell>
                                <TableCell align='right'>
                                    <Grid item> {getCurrencyByNumber(paymentToDo.amountToPay)} </Grid>
                                </TableCell>
                                <TableCell align='right'>
                                    <Grid item> {getCurrencyByNumber(paymentToDo.totalAmount)} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentToDo.lastWarning} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3} md={3} xs={12}>
                                            <Tooltip title={<FormattedMessage id="goToDossier" />} aria-label="Dossier">
                                                <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${paymentToDo.dossierId}`}>
                                                    <IconFiles fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={12}>
                                            {
                                                paymentToDo.hasEmail &&
                                                <Tooltip title={<FormattedMessage id="sendEmail" />} onClick={() => updateEntityToSendCommunication(paymentToDo, CommunicationType.Email)} aria-label="Edit">
                                                    <IconButton>
                                                        <EmailIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={12}>
                                            {
                                                paymentToDo.hasPhoneNumber &&
                                                <Tooltip title={<FormattedMessage id="sendWhatsapp" />} onClick={() => updateEntityToSendCommunication(paymentToDo, CommunicationType.Whatsapp)} aria-label="Edit">
                                                    <IconButton>
                                                        <WhatsAppIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={12}>
                                            <Tooltip title={<FormattedMessage id="downloadBulletin" />} onClick={() => updateEntityToDownloadBulletin(paymentToDo)} aria-label="Edit">
                                                <IconButton>
                                                    <ReceiptIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </AlternateTableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PaymentsToDoPage;