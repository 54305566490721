export interface UserPaymentsNumber {
    paymentsToDo: number;
    paymentsDone: number;
    paymentsPending: number;
}

export const defaultUserPaymentsNumber: UserPaymentsNumber = {
    paymentsToDo: 0,
    paymentsDone: 0,
    paymentsPending: 0
};