import { enqueueSnackbar } from "notistack";
import { Buffer } from "buffer";
import CodiceFiscale from "codice-fiscale-js";
import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
import { PaginationQuery } from "types/paginationQuery";
import Cookies from 'js-cookie';

const formatter = new Intl.NumberFormat('default', {
	style: 'currency',
	currency: 'EUR',
});

export const getFormValue = (e: any) => {
	let name = e.target.name;
	let value = "";

	if (e.target.type === "checkbox") {
		value = e.target.checked;
	} else {
		value = e.target.value;
	}
	return { name, value };
};

export function composeNotistackMessage(messageText: string, messageVariant: any) {
	enqueueSnackbar(messageText, {
		anchorOrigin: {
			horizontal: 'left',
			vertical: 'top'
		},
		variant: messageVariant
	});
}

export function toFirstUpper(string?: string | undefined) {
	return string !== undefined && string !== null ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function getShortDate(date: any) {
	if (!isNotNullOrUndefined(date)) {
		return '';
	} else {
		return new Date(date).toLocaleString([], { year: "numeric", month: "2-digit", day: "2-digit" });
	}
}

export function getShortDateWithoutDays(date: any) {
	if (!isNotNullOrUndefined(date)) {
		return '';
	} else {
		return new Date(date).toLocaleString([], { year: "numeric", month: "2-digit" });
	}
}

export function handleDownloadFile(base64: string, fileName: string) {
	const link = document.createElement('a');
	link.href = base64;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export const encodeBase64 = (toEncode: string): string => `data:*/*;base64,${Buffer.from(toEncode, 'binary').toString('base64')}`;

export const decodeBase64 = (toDecode: string): string => Buffer.from(toDecode, 'base64').toString('binary');

export const isNotNullOrUndefined = (value: any): boolean => value !== null && value !== undefined && value !== "";

export const getWithTwoDecimal = (value: any): string => Number(value).toFixed(2);

export const getFiscalCode = (name: string, surname: string, birthdate: string, sex: number, birthCountry: string, birthState: string, birthCity: string): string => {
	dayjs.extend(utc);

	let fiscalCode: string = '';

	let date: Dayjs | null;
	try {
		date = dayjs.utc(birthdate);
	} catch (e: any) {
		date = null;
	}

	if (date !== null) {
		const isItaly: boolean = birthCountry.toLowerCase() == 'italia';
		const birthPlace: string = isItaly ? toFirstUpper(birthCity) : toFirstUpper(birthCountry);
		const birthplaceProvincia: string = isItaly ? birthState.toUpperCase() : 'EE';

		const fiscalCodeObject = new CodiceFiscale({
			name: name,
			surname: surname,
			gender: sex == 1 ? 'M' : 'F',
			day: date.date(),
			month: date.month() + 1,
			year: date.year(),
			birthplace: birthPlace,
			birthplaceProvincia: birthplaceProvincia
		});

		fiscalCode = fiscalCodeObject.cf;
	}

	return fiscalCode;
}

export const saveFiltersPaginationCookie = (tenantId: string, key: string, object: { filters: object, paginationQuery: PaginationQuery }, ttl: number) => {
	const filtersObjectWithTenant = {
		[tenantId]: object
	};

	Cookies.set(
		key,
		JSON.stringify(filtersObjectWithTenant),
		{
			expires: ttl
		}
	);
}

export const retrieveCookieByKey = (tenantId: string, key: string) => {
	try {
		const cookieByKey = JSON.parse(Cookies.get(key) ?? '');

		return cookieByKey[tenantId];
	} catch (e: any) {
		return undefined;
	}
}

export const getCurrencyByNumber = (value: any) => {
	const numberToConvert: number = isNaN(Number(value)) ? Number(0) : Number(value);

	return formatter.format(numberToConvert);
}