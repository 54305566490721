import { PlaceServicePaymentsReport } from "./placeServicePaymentsReport";

export interface UserPaymentsReport {
    payed: Array<PlaceServicePaymentsReport>;
    toPay: Array<PlaceServicePaymentsReport>;
    paymentsToVerify: Array<PlaceServicePaymentsReport>;
}

export const defaultUserPaymentsReport: UserPaymentsReport = {
    payed: [],
    toPay: [],
    paymentsToVerify: []
};