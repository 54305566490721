import { DeleteTwoTone } from "@mui/icons-material";
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Tooltip, IconButton, Box, Collapse, Drawer, Typography, Slide, Checkbox, FormControlLabel, Menu, MenuProps, alpha, styled, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, Step, StepLabel, Stepper } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import AlternateTableRow from "ui-component/AlternateTableRow";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import { FC, MutableRefObject, forwardRef, useEffect, useRef, useState } from "react";
import PostiList from "pages/Posti/PostiList";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ServiziList from "pages/Servizi/ServiziList";
import { composeNotistackMessage, getCurrencyByNumber, getShortDate, getShortDateWithoutDays, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { PostoPratica } from "types/postoPratica";
import { ServizioPosto } from "types/servizioPosto";
import { PraticaService } from "services/PraticaServices";
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Pagamento } from "types/pagamento";
import { PagamentoService } from "services/PagamentoServices";
import PagamentoUpsert from "pages/Pagamenti/PagamentoUpsert";
import { TipoPagamento } from "types/enums/TipoPagamento";
import TableRowsLoader from "ui-component/TableRowsLoader";
import { PostoPraticaData } from "types/postoPraticaData";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { Posto } from "types/posto";
import { useDispatch } from "store";
import { setSplittedDossierPlacesDossiers } from "store/slices/splitDossierPlacesDossiersSlice";
import { TenantDocument } from "types/tenantDocument";
import { Utente } from "types/utente";
import { serialize, deserialize } from "ui-component/SlateEditor/utils/SlateUtilityFunctions";
import { TenantService } from "services/TenantServices";
import { Pratica } from "types/pratica";
import SlateEditor from "ui-component/SlateEditor/Editor";
import SignatureCanvas from 'react-signature-canvas';
import { Document, Page } from 'react-pdf';
import { TransitionProps } from '@mui/material/transitions';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import { ServizioPostoService } from "services/ServizioPostoServices";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ServizioPostoWithPeriodData } from "types/servizioPostoWithPeriodData";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PlaceStepWithMenuProps {
    postoPraticaData: PostoPraticaData;
    checkedPlacesDossierIds: Array<string>;
    checkPlaceDossier: (e: any, postoPraticaId: string) => void;
    tenantDocuments: Array<TenantDocument>;
    assignedUser: Utente | undefined;
    addDocumentToDossier: (newFileName: string, newFileBase64: string) => void;
    setPlaceDossierToSetService: Function;
    setOpenServicesDrawer: Function;
    setEntityToRemoveData: Function;
    setRemovePlaceDossierDialogOpen: Function;

    praticaId: string;
}

const PlaceStepWithMenu: FC<PlaceStepWithMenuProps> = (props) => {
    const { postoPraticaData, checkedPlacesDossierIds, checkPlaceDossier, tenantDocuments, assignedUser, addDocumentToDossier,
        setPlaceDossierToSetService, setOpenServicesDrawer, setEntityToRemoveData, setRemovePlaceDossierDialogOpen, praticaId } = props;

    const intl = useIntl();

    const tenantService = new TenantService();
    const praticaService = new PraticaService();

    const navigate = useNavigate();

    const [dossierDocumentDialogOpen, setDossierDocumentDialogOpen] = useState<boolean>(false);
    const [dossierDocumentAnchorEl, setDossierDocumentAnchorEl] = useState<null | HTMLElement>(null);
    const dossierDocumentMenuOpen = Boolean(dossierDocumentAnchorEl);

    const [editView, setEditView] = useState<boolean>(false);

    const [numPages, setNumPages] = useState<number>();

    const signCanvas = useRef() as MutableRefObject<any>;

    const [dossierDocumentId, setDossierDocumentIdToDownload] = useState<string>('');

    const [dossierDocumentBase64, setDossierDocumentBase64] = useState<string>('');
    const [dossierDocumentTemplate, setDossierDocumentTemplate] = useState<any[]>([
        {
            type: "paragraph",
            children: [{ text: "" }],
        },
    ]);

    const handleDossierDocumentDialogOpen = () => {
        setDossierDocumentDialogOpen(true);
    }

    const handleDossierDocumentDialogClose = () => {
        setDossierDocumentDialogOpen(false);
        setEditView(false);
        setActiveStep(0);
    }

    const handleDossierDocumentMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setDossierDocumentAnchorEl(event.currentTarget);
    }

    const handleDossierDocumentMenuClose = () => {
        setDossierDocumentAnchorEl(null);
    }

    // TODO: Capire quali placeholder vogliamo fillare (parlare con Aserio)
    const downloadAndSaveFilledDossierDocument = async (placeDossierId: string, requiredSignature: boolean) => {
        if (!signCanvas.current.isEmpty() || !requiredSignature) {
            const signatureBase64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            const dossierDocument = await praticaService.GetFilledDocumentByTemplate({
                templateHtmlToString: serialize(documentToReviewTemplate),
                dataToFillTemplate: {
                    userSignature: requiredSignature ? signatureBase64 : undefined,
                    dossierId: praticaId,
                    placeDossierId: placeDossierId
                }
            });

            const dossierDocumentName: string = tenantDocuments.find(tenantDossierDocument => tenantDossierDocument.id == dossierDocumentId)!.fileName;

            const dossierDocumentFileName: string = `${dossierDocumentName}-${postoPraticaData.strutturaDenominazione}-${postoPraticaData.postoNumero}.pdf`;

            if (requiredSignature) addDocumentToDossier(dossierDocumentFileName, dossierDocument);

            handleDossierDocumentDialogClose();

            handleDownloadFile(dossierDocument, dossierDocumentFileName);

            composeNotistackMessage(intl.formatMessage({ id: 'documentSaved' }), 'success')
            setActiveStep(0);
        } else {
            composeNotistackMessage(intl.formatMessage({ id: 'signatureRequired' }), 'error')
        }
    }

    // TODO: Capire quali placeholder vogliamo fillare (parlare con Aserio)
    const getDossierDocumentPdf = async (documentId: string, placeDossierId: string) => {
        setDossierDocumentIdToDownload(documentId);

        const dossierDocumentBase64Pdf = await praticaService.GetDossierDocument({
            dossierDocumentId: documentId,
            dataToFillTemplate: {
                dossierId: praticaId,
                placeDossierId: placeDossierId
            }
        });

        setDossierDocumentBase64(dossierDocumentBase64Pdf);
    }

    const getDossierDocumentTemplate = async (documentId: string) => {
        const retrievedDocument: TenantDocument = await tenantService.GetTenantDocument(documentId);

        const htmlToString: string | undefined = retrievedDocument.documentHtmlToString;

        if (htmlToString) {
            const document = new DOMParser().parseFromString(htmlToString, "text/html");
            setDossierDocumentTemplate(deserialize(document.body));
        }
    }

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }

    const clear = () => signCanvas.current.clear();

    const handleEditorStateChange = (editor: any) => {
        setDossierDocumentTemplate(editor);
    };

    const handleChangeDocumentView = async (placeDossierId: string) => {
        if (editView) {
            const dossierDocument = await praticaService.GetFilledDocumentByTemplate({
                templateHtmlToString: serialize(dossierDocumentTemplate),
                dataToFillTemplate: {
                    dossierId: praticaId,
                    placeDossierId: placeDossierId
                }
            });
            setDossierDocumentBase64(dossierDocument);
        }

        setEditView(!editView);
    }

    const [documentToReviewTemplate, setDocumentToReviewTemplate] = useState<any[]>([
        {
            type: "paragraph",
            children: [{ text: "" }],
        },
    ]);

    const handleDocumentToReviewStateChange = (editor: any) => {
        setDocumentToReviewTemplate(editor);
    };

    const getDocumentToReview = async (placeDossierId: string) => {
        const documentToReview = await praticaService.GetDocumentToReviewByTemplate({
            templateHtmlToString: serialize(dossierDocumentTemplate),
            dataToFillTemplate: {
                dossierId: praticaId,
                placeDossierId: placeDossierId
            }
        });

        const document = new DOMParser().parseFromString(documentToReview, "text/html");

        setDocumentToReviewTemplate(deserialize(document.body));
    }

    const PreliminaryMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const steps = ['editDocument', 'review'];
    const [activeStep, setActiveStep] = useState(0);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handlePreviousStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Grid item>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '15px' }}>
                            <Button size="small" variant="contained" endIcon={editView ? <ChromeReaderModeIcon /> : <EditIcon />} onClick={() => handleChangeDocumentView(postoPraticaData.postoPraticaId)}>
                                <FormattedMessage id={editView ? "view" : "edit"} />
                            </Button>
                        </Box>
                        {
                            editView ?
                                dossierDocumentTemplate.length > 0 &&
                                <Box width='880px'>
                                    <SlateEditor initialValue={dossierDocumentTemplate} onChange={handleEditorStateChange} readOnly={false} />
                                </Box> :
                                <Document
                                    file={dossierDocumentBase64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={<LinearProgress />}
                                    noData={<LinearProgress />}
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (_el, index) => (
                                                <div key={`div${index + 1}`} style={{ marginTop: '15px', border: '1px solid black' }}>
                                                    <Page
                                                        width={window.innerWidth > 1024 ? 1024 : window.innerWidth}
                                                        key={`page_${index + 1}`}
                                                        pageNumber={index + 1}
                                                        renderAnnotationLayer={false}
                                                        renderTextLayer={false}
                                                    />
                                                </div>
                                            )
                                        )
                                    }
                                </Document>
                        }
                    </Grid>
                );
            case 1:
                return (
                    <>
                        <Grid item>
                            <h1> <FormattedMessage id="finalReview" /> </h1>
                        </Grid>
                        <Grid item>
                            {
                                documentToReviewTemplate.length > 0 &&
                                <Box width='880px'>
                                    <SlateEditor initialValue={documentToReviewTemplate} onChange={handleDocumentToReviewStateChange} readOnly={false} />
                                </Box>
                            }
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <SignatureCanvas ref={signCanvas} penColor="black"
                                    canvasProps={{ className: "sigCanvas", width: '350', height: '87.5px' }} throttle={25} />
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clear();
                                    }}
                                    sx={{ mt: '10px' }}
                                >
                                    <FormattedMessage id="clear" />
                                </Button>
                            </Box>
                        </Grid>
                    </>
                );
        }
    }

    return (
        <AlternateTableRow key={postoPraticaData.postoPraticaId}>
            <Dialog
                open={dossierDocumentDialogOpen}
                onClose={handleDossierDocumentDialogClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "95%",
                        },
                    },
                }}
            >
                <DialogTitle>
                    <FormattedMessage id="preliminaryReport" />
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {
                                steps.map((label) =>
                                (
                                    <Step key={label}>
                                        <StepLabel> <FormattedMessage id={label} /> </StepLabel>
                                    </Step>
                                ))
                            }
                        </Stepper>
                        <Box sx={{ margin: 2 }}>
                            <Grid container direction="column" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} spacing={3}>
                                {
                                    getStepContent()
                                }
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handlePreviousStep}
                                color="secondary"
                            >
                                <FormattedMessage id="previous" />
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {
                                activeStep === steps.length - 1 ?
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                downloadAndSaveFilledDossierDocument(postoPraticaData.postoPraticaId, false);
                                            }}
                                            sx={{ mr: 2 }}
                                        >
                                            <FormattedMessage id="download" />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                downloadAndSaveFilledDossierDocument(postoPraticaData.postoPraticaId, true);
                                            }}
                                        >
                                            <FormattedMessage id="confirm" />
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            await getDocumentToReview(postoPraticaData.postoPraticaId);
                                            handleNextStep();
                                        }}
                                        color="secondary"
                                    >
                                        <FormattedMessage id="next" />
                                    </Button>
                            }
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <TableCell>
                <Checkbox key={`check-placeDossier-${postoPraticaData.postoPraticaId}`}
                    checked={checkedPlacesDossierIds.includes(postoPraticaData.postoPraticaId)}
                    onChange={(e: any) => checkPlaceDossier(e, postoPraticaData.postoPraticaId)}
                />
            </TableCell>
            <TableCell> {postoPraticaData.strutturaDenominazione} </TableCell>
            <Tooltip title={<FormattedMessage id="goToPlace" />} aria-label="Go to place">
                <TableCell sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#dedcdc'
                    }
                }}
                    onClick={() => { navigate(`/posto/edit?postoId=${postoPraticaData.postoId}`) }}
                >
                    {postoPraticaData.postoNumero}
                    <ArrowOutwardSharpIcon fontSize="small" sx={{ ml: '3px', fontSize: '13px' }} />
                </TableCell>
            </Tooltip>
            <TableCell> {postoPraticaData.postoProprietarioCognome} </TableCell>
            <TableCell> {postoPraticaData.postoProprietarioNome} </TableCell>
            <TableCell> <FormattedMessage id={postoPraticaData.postoProprietarioMorto ? 'yes' : 'no'} /> </TableCell>
            <TableCell> {getShortDate(postoPraticaData.postoProprietarioDataNascita)} </TableCell>
            <TableCell align="right"> {getCurrencyByNumber(postoPraticaData.importoTotale)} </TableCell>
            <TableCell align="right"> {getCurrencyByNumber(postoPraticaData.pagatoTotale)} </TableCell>
            <TableCell align="right"> {getCurrencyByNumber(postoPraticaData.importoTotale! - postoPraticaData.pagatoTotale!)} </TableCell>
            <TableCell align="right">
                <Tooltip title={<FormattedMessage id="services" />} aria-label="Services">
                    <IconButton onClick={() => {
                        setPlaceDossierToSetService(postoPraticaData);
                        setOpenServicesDrawer(true);
                    }}>
                        <HomeRepairServiceIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                    <IconButton onClick={() => {
                        setEntityToRemoveData({
                            id: postoPraticaData.postoPraticaId,
                            name: postoPraticaData.postoNumero
                        });
                        setRemovePlaceDossierDialogOpen(true);
                    }}>
                        <DeleteTwoTone fontSize="small" />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Button
                    id="new-preliminary-button"
                    aria-controls={dossierDocumentMenuOpen ? 'new-preliminary-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={dossierDocumentMenuOpen ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    sx={{ mr: 2 }}
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleDossierDocumentMenuOpen}
                    startIcon={<ArticleIcon />}
                >
                    <FormattedMessage id="choose" />
                </Button>
                <PreliminaryMenu
                    id="new-preliminary-menu"
                    MenuListProps={{
                        'aria-labelledby': 'new-preliminary-button',
                    }}
                    anchorEl={dossierDocumentAnchorEl}
                    open={dossierDocumentMenuOpen}
                    onClose={handleDossierDocumentMenuClose}
                >
                    {
                        tenantDocuments.map((tenantDossierDocument: TenantDocument) => (
                            <MenuItem
                                onClick={async () => {
                                    handleDossierDocumentMenuClose();
                                    handleDossierDocumentDialogOpen();
                                    await getDossierDocumentPdf(tenantDossierDocument.id!, postoPraticaData.postoPraticaId);
                                    await getDossierDocumentTemplate(tenantDossierDocument.id!);
                                }}
                                disableRipple
                            >
                                {tenantDossierDocument.fileName}
                            </MenuItem>
                        ))
                    }
                </PreliminaryMenu>
            </TableCell>
        </AlternateTableRow>
    );
}

interface PlacesStepProps {
    pratica: Pratica;

    assignedUser: Utente | undefined;
    tenantDocuments: Array<TenantDocument>;
    addDocumentToDossier: (newFileName: string, newFileBase64: string) => void;
}

const PlacesStep: FC<PlacesStepProps> = (props) => {
    const { pratica, assignedUser, tenantDocuments, addDocumentToDossier } = props;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [isServicesLoading, setIsServicesLoading] = useState<boolean>(false);
    const [isPaymentsLoading, setIsPaymentsLoading] = useState<boolean>(false);

    const praticaService = new PraticaService();
    const pagamentoService = new PagamentoService();
    const placeServiceService = new ServizioPostoService();

    const [placeDossierToSetService, setPlaceDossierToSetService] = useState<PostoPraticaData | null>(null);
    const [assignedPostiPratica, setAssignedPostiPratica] = useState<Array<PostoPraticaData>>([]);
    const [assignedPlacesIds, setAssignedPlacesIds] = useState<Array<string>>([]);
    const [placeServices, setPlaceServices] = useState<Array<ServizioPostoWithPeriodData>>([]);
    const [servicesIds, setServicesIds] = useState<Array<string>>([]);
    const [postoServizioPayments, setPostoServizioPayments] = useState<Array<Pagamento>>([]);

    const [openAssignPostoCollapse, setOpenAssignPostoCollapse] = useState<boolean>(false);
    const [openServicesDrawer, setOpenServicesDrawer] = useState<boolean>(false);
    const [postoServizioToSetPayments, setPostoServizioToSetPayments] = useState<ServizioPostoWithPeriodData | null>(null);
    const [openUpsertPaymentCollapse, setOpenUpsertPaymentCollapse] = useState<boolean>(false);
    const [openEditPagamentoCollapse, setOpenEditPagamentoCollapse] = useState<boolean>(false);
    const [paymentToHandle, setPaymentToHandle] = useState<Pagamento | undefined>(undefined);

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            let retrievedPostiPratica: Array<PostoPraticaData> = await praticaService.GetPraticaPosti(pratica.id!);
            setAssignedPostiPratica(retrievedPostiPratica);
            setAssignedPlacesIds(retrievedPostiPratica.map((postoPratica: PostoPraticaData) => postoPratica.postoId));

            setIsPageLoading(false);
        })();
    }, [])

    useEffect(() => {
        setIsServicesLoading(true);

        (async () => {
            if (placeDossierToSetService !== null) {
                await getPostoPraticaServices();
            }

        })()
    }, [placeDossierToSetService])

    useEffect(() => {
        if (postoServizioToSetPayments !== null) {
            getPostoServizioPayments();
        }
    }, [postoServizioToSetPayments])

    const assignPosto = async (postoId: string) => {
        setIsPageLoading(true);

        await praticaService.AssignPlacesToPratica(pratica.id!, [postoId]);
        await updateAssignedPlaces();
    }

    const [checkedPlacesIds, setCheckedPlacesIds] = useState<Array<string>>([]);
    const [checkedPlacesDossierIds, setCheckedPlacesDossiersIds] = useState<Array<string>>([]);

    const checkAllPlaces = (e: any, posti: Array<Posto>) => {
        let newCheckedPlaces: Array<string> = [];
        if (e.target.checked) {
            newCheckedPlaces = posti
                .filter((place: Posto) => !assignedPlacesIds.includes(place.id))
                .map((place: Posto) => place.id);
        }
        setCheckedPlacesIds(newCheckedPlaces);
    }

    const checkPlace = (e: any, postoId: string) => {
        let newCheckedPlaces: Array<string> = [];
        newCheckedPlaces = e.target.checked ? [...checkedPlacesIds, postoId] : checkedPlacesIds.filter((checkedPlace) => checkedPlace !== postoId);
        setCheckedPlacesIds(newCheckedPlaces);
    }

    const checkAllPlacesDossier = (e: any, postiPratica: Array<PostoPraticaData>) => {
        let newCheckedPlacesDossier: Array<string> = [];
        if (e.target.checked) {
            newCheckedPlacesDossier = postiPratica
                .map((placeDossier: PostoPraticaData) => placeDossier.postoPraticaId);
        }
        setCheckedPlacesDossiersIds(newCheckedPlacesDossier);
    }

    const checkPlaceDossier = (e: any, postoPraticaId: string) => {
        let newCheckedPlacesDossier: Array<string> = [];
        newCheckedPlacesDossier = e.target.checked ? [...checkedPlacesDossierIds, postoPraticaId] : checkedPlacesDossierIds.filter((checkedPlaceDossier) => checkedPlaceDossier !== postoPraticaId);
        setCheckedPlacesDossiersIds(newCheckedPlacesDossier);
    }

    const massivePlaceAssignment = async () => {
        setIsPageLoading(true);

        await praticaService.AssignPlacesToPratica(pratica.id!, checkedPlacesIds);
        await updateAssignedPlaces();
    }

    const deletePosto = async (postoPraticaId: string) => {
        setIsPageLoading(true);

        await praticaService.RemovePostoFromPratica(postoPraticaId);
        await updateAssignedPlaces();
    }

    const updateAssignedPlaces = async () => {
        let retrievedPosti: Array<PostoPraticaData> = await praticaService.GetPraticaPosti(pratica.id!!);
        setAssignedPostiPratica(retrievedPosti);
        setAssignedPlacesIds(retrievedPosti.map((postoPratica: PostoPraticaData) => postoPratica.postoId));

        setCheckedPlacesIds([]);

        setIsPageLoading(false);
    }

    const getPostoPraticaServices = async () => {
        const retrievedPlaceServices: Array<ServizioPostoWithPeriodData> = await praticaService.GetPostoServizi(placeDossierToSetService!.postoPraticaId);
        setPlaceServices(retrievedPlaceServices);
        setServicesIds(retrievedPlaceServices.map((servizioPosto: ServizioPosto) => servizioPosto.servizioAssociatoId));

        setIsServicesLoading(false);
    }

    const assignService = async (servizioId: string) => {
        setIsServicesLoading(true);

        await praticaService.AssignServizioToPosto({ postoPraticaId: placeDossierToSetService!.postoPraticaId, servizioId: servizioId });
        await getPostoPraticaServices();
        await updateAssignedPlaces();
    }

    const deleteService = async (postoServizioId: string) => {
        setIsServicesLoading(true);

        await praticaService.RemoveServizioFromPosto(postoServizioId);
        await getPostoPraticaServices();
        await updateAssignedPlaces();
    }

    const deletePostoServizioPayment = async (postoServizioPaymentId: string) => {

        setOpenEditPagamentoCollapse(false);
        setPaymentToHandle(undefined);

        await pagamentoService.RemoveServizioPostoPagamento(postoServizioPaymentId);
        getPostoServizioPayments();
    }

    const getPostoServizioPayments = () => {
        setIsPaymentsLoading(true);

        (async () => {
            const retrievedPlaceServicePayments: Array<Pagamento> = await pagamentoService.GetServizioPostoPagamenti(postoServizioToSetPayments!.placeServiceValidPeriodId);
            setPostoServizioPayments(retrievedPlaceServicePayments);

            await updateAssignedPlaces();
            await getPostoPraticaServices();

            setIsPaymentsLoading(false);
        })()
    }

    const handleEditPayment = (payment?: Pagamento) => {
        setPaymentToHandle(undefined);
        setOpenEditPagamentoCollapse(false);

        if (payment?.id != paymentToHandle?.id) {
            setTimeout(() => {
                setPaymentToHandle(payment);
                setOpenEditPagamentoCollapse(true);
            }, 150);
        }
    }

    const splitDossier = () => {
        const placesDossierToSplit: Array<PostoPraticaData> = assignedPostiPratica.filter((postoPratica: PostoPraticaData) => checkedPlacesDossierIds.includes(postoPratica.postoPraticaId));
        dispatch(setSplittedDossierPlacesDossiers(placesDossierToSplit));
        navigate('/dossiers/create');
    }

    const [removePlaceDossierDialogOpen, setRemovePlaceDossierDialogOpen] = useState<boolean>(false);
    const [removeServiceDialogOpen, setRemoveServiceDialogOpen] = useState<boolean>(false);
    const [removePaymentDialogOpen, setRemovePaymentDialogOpen] = useState<boolean>(false);
    const [entityToRemoveData, setEntityToRemoveData] = useState<{ id: string, name: string } | undefined>();

    const intl = useIntl();

    const reactivatePlaceService = async (placeServiceId: string) => {
        try {
            await placeServiceService.ReactivatePlaceService(placeServiceId);

            await getPostoPraticaServices();

            composeNotistackMessage(intl.formatMessage({ id: 'serviceReactivatedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    return (<>
        <DialogDeleteElement
            open={removePlaceDossierDialogOpen}
            onClose={() => setRemovePlaceDossierDialogOpen(false)}
            onCancel={() => setRemovePlaceDossierDialogOpen(false)}
            onConfirm={() => {
                setRemovePlaceDossierDialogOpen(false)
                deletePosto(entityToRemoveData!.id)
            }}
            entityToDeleteInfo={entityToRemoveData?.name ?? ''}
            customMessageIntlId="confirmDeletion"
            key="removePlaceDossier"
        />
        <DialogDeleteElement
            open={removeServiceDialogOpen}
            onClose={() => setRemoveServiceDialogOpen(false)}
            onCancel={() => setRemoveServiceDialogOpen(false)}
            onConfirm={() => {
                setRemoveServiceDialogOpen(false)
                deleteService(entityToRemoveData!.id)
            }}
            entityToDeleteInfo={entityToRemoveData?.name ?? ''}
            customMessageIntlId="confirmDeletion"
            key="removeService"
        />
        <DialogDeleteElement
            open={removePaymentDialogOpen}
            onClose={() => setRemovePaymentDialogOpen(false)}
            onCancel={() => setRemovePaymentDialogOpen(false)}
            onConfirm={() => {
                setRemovePaymentDialogOpen(false)
                deletePostoServizioPayment(entityToRemoveData!.id)
            }}
            entityToDeleteInfo={entityToRemoveData?.name ?? ''}
            customMessageIntlId="confirmDeletion"
            key="removePayment"
        />
        <Button sx={{ mb: 3 }} variant="contained" onClick={() => setOpenAssignPostoCollapse(!openAssignPostoCollapse)} startIcon={<AddIcon />}>
            <FormattedMessage id="addPlace" />
        </Button>
        <Collapse in={openAssignPostoCollapse}>
            <Box sx={{
                padding: "5px",
                overflowY: 'auto',
            }}>

                <PostiList
                    alreadyAssignedPostiIds={assignedPlacesIds}
                    assignPostoToEntity={assignPosto}
                    massivePlaceAssignment={massivePlaceAssignment}

                    checkedPlaces={checkedPlacesIds}
                    checkAllPlaces={checkAllPlaces}
                    checkPlace={checkPlace}
                />
            </Box>
        </Collapse>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <PurpleStyledTableCell>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<b><FormattedMessage id="all" /></b>}
                                labelPlacement="end"
                                onChange={(e: any) => {
                                    checkAllPlacesDossier(e, assignedPostiPratica);
                                }}
                            />
                        </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="structure" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="placeNumber" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="surname" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="name" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="decuius" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell align="right"> <FormattedMessage id="totalAmount" /> (€) </PurpleStyledTableCell>
                        <PurpleStyledTableCell align="right"> <FormattedMessage id="totalPayed" /> (€) </PurpleStyledTableCell>
                        <PurpleStyledTableCell align="right"> <FormattedMessage id="totalToPay" /> (€) </PurpleStyledTableCell>
                        <PurpleStyledTableCell align="right"> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                        <PurpleStyledTableCell> <FormattedMessage id="addDocument" /> </PurpleStyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            <TableRowsLoader rowsNum={3} colsNum={12} /> :
                            assignedPostiPratica.length != 0 && assignedPostiPratica.map((postoPraticaData: PostoPraticaData, index: number) => (
                                <PlaceStepWithMenu key={index}
                                    postoPraticaData={postoPraticaData}
                                    checkedPlacesDossierIds={checkedPlacesDossierIds}
                                    checkPlaceDossier={checkPlaceDossier}
                                    tenantDocuments={tenantDocuments}
                                    assignedUser={assignedUser}
                                    addDocumentToDossier={addDocumentToDossier}
                                    setPlaceDossierToSetService={setPlaceDossierToSetService}
                                    setOpenServicesDrawer={setOpenServicesDrawer}
                                    setEntityToRemoveData={setEntityToRemoveData}
                                    setRemovePlaceDossierDialogOpen={setRemovePlaceDossierDialogOpen}
                                    praticaId={pratica.id!}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Button variant="contained" sx={{ mt: '10px' }} disabled={checkedPlacesDossierIds.length < 1} onClick={splitDossier}>
                <FormattedMessage id="splitDossier" />
            </Button>
        </TableContainer>
        <Table sx={{ mt: 2 }}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            <FormattedMessage id="total" />
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {getCurrencyByNumber(assignedPostiPratica.reduce((tot, postoPratica) => tot + postoPratica.importoTotale!, 0))}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            <FormattedMessage id="totalPayed" />
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {getCurrencyByNumber(assignedPostiPratica.reduce((tot, postoPratica) => tot + postoPratica.pagatoTotale!, 0))}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Drawer
            anchor="bottom"
            open={openServicesDrawer}
            onClose={() => {
                setOpenServicesDrawer(false);
                setOpenUpsertPaymentCollapse(false);
                setOpenEditPagamentoCollapse(false);
                setPostoServizioToSetPayments(null);
            }}
        >
            {
                postoServizioToSetPayments === null &&
                <Slide direction="right" in={postoServizioToSetPayments === null}>
                    <Box sx={{ height: '500px', p: 2 }}>
                        <Typography variant="h3" sx={{ mt: 1, mb: 2 }}> <FormattedMessage id="assignedServicesToPlace" />: {placeDossierToSetService?.postoNumero} - <FormattedMessage id="structure" />: {placeDossierToSetService?.strutturaDenominazione} - <FormattedMessage id="dossier" />: {pratica.numero!} </Typography>
                        <TableContainer component={Paper} sx={{ mb: 4 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <PurpleStyledTableCell> <FormattedMessage id="invoice" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="code" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="fourthField" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="startDate" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="endDate" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="quantity" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="totalAmount" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="payed" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="toPay" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="status" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell align="right"> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        isServicesLoading ?
                                            <TableRowsLoader rowsNum={4} colsNum={13} /> :
                                            placeServices !== null &&
                                            placeDossierToSetService !== null &&
                                            placeServices.map((placeService: ServizioPostoWithPeriodData) => (
                                                <AlternateTableRow key={placeService.id} rowColor={isNotNullOrUndefined(placeService.invoiceNumber) ? 'lightgreen' : placeService.active ? '' : 'lightgray'} >
                                                    <TableCell> {placeService.invoiceNumber} </TableCell>
                                                    <TableCell> {placeService.descrizione} </TableCell>
                                                    <TableCell> {placeService.bankTransferPaymentCode} </TableCell>
                                                    <TableCell> {placeService.quartoCampo} </TableCell>
                                                    <TableCell> {getShortDateWithoutDays(placeService.startPeriod)} </TableCell>
                                                    <TableCell> {getShortDateWithoutDays(placeService.endPeriod)} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(placeService.importo)} </TableCell>
                                                    <TableCell align="right"> {placeService.quantita} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(placeService.importo * placeService.quantita)} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(placeService.pagato)} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber((placeService.importo * placeService.quantita) - placeService.pagato!)} </TableCell>
                                                    <TableCell align="right"> <FormattedMessage id={placeService.active ? 'active' : 'notActive'} /> </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title={<FormattedMessage id="payment" />} aria-label="Payment">
                                                            <IconButton onClick={() => setPostoServizioToSetPayments(placeService)}>
                                                                <AttachMoneyIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {
                                                            (placeService.invoiceNumber === null || placeService.invoiceNumber === undefined) ?
                                                                <>
                                                                    {
                                                                        placeService.active ?
                                                                            <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                                                <IconButton component={RouterLink} to={`/placeServices/edit?placeServiceId=${placeService.id}&dossierId=${pratica.id!}`}>
                                                                                    <EditIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip> :
                                                                            <>
                                                                                {
                                                                                    placeService.showReactivateButton &&
                                                                                    <Tooltip title={<FormattedMessage id="reactivate" />} aria-label="Reactivate">
                                                                                        <IconButton onClick={() => reactivatePlaceService(placeService.id)}>
                                                                                            <CheckCircleIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                }
                                                                            </>
                                                                    }
                                                                    <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                                                        <IconButton onClick={() => {
                                                                            setEntityToRemoveData({
                                                                                id: placeService.placeServiceValidPeriodId,
                                                                                name: placeService.descrizione
                                                                            });
                                                                            setRemoveServiceDialogOpen(true);
                                                                        }}>
                                                                            <DeleteTwoTone fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </> : <></>
                                                        }
                                                    </TableCell>
                                                </AlternateTableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ServiziList fromExternal={true} assignServiceToEntity={assignService} assignedServicesIds={placeDossierToSetService == null ? [] : servicesIds} />
                    </Box>
                </Slide>
            }
            {
                postoServizioToSetPayments !== null &&
                <Slide direction="left" in={postoServizioToSetPayments !== null}>
                    <Box sx={{ height: '500px', p: 2 }}>
                        <IconButton onClick={() => {
                            setPaymentToHandle(undefined);
                            setPostoServizioToSetPayments(null);
                            setOpenUpsertPaymentCollapse(false);
                            setOpenEditPagamentoCollapse(false);
                        }}>
                            <ArrowBackIosNewIcon fontSize="small" />
                        </IconButton>
                        <Box sx={{ px: 1, mt: 1 }}>
                            <Typography variant="h3" sx={{ mt: 1, mb: 2 }}> <FormattedMessage id="service" />: {postoServizioToSetPayments.descrizione} - <FormattedMessage id="place" />: {placeDossierToSetService?.postoNumero} - <FormattedMessage id="structure" />: {placeDossierToSetService?.strutturaDenominazione}</Typography>
                            <Button sx={{ mb: 3 }} variant="contained" onClick={() => setOpenUpsertPaymentCollapse(!openUpsertPaymentCollapse)} startIcon={<AddIcon />}>
                                <FormattedMessage id="addPayment" />
                            </Button>
                            <Collapse in={openUpsertPaymentCollapse}>
                                <PagamentoUpsert isAdd={true} servizioPostoId={postoServizioToSetPayments.id} updateServizioPostoPagamenti={getPostoServizioPayments} />
                            </Collapse>
                            <TableContainer component={Paper} sx={{ mb: 4 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <PurpleStyledTableCell> <FormattedMessage id="invoice" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="code" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="date" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="header" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> (€) </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="paymentMethod" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            isPaymentsLoading ?
                                                <TableRowsLoader rowsNum={4} colsNum={8} /> :
                                                postoServizioPayments.length != 0 && postoServizioPayments.map((postoServizioPayment: Pagamento) => (
                                                    <AlternateTableRow
                                                        key={postoServizioPayment.id}
                                                        rowColor={postoServizioPayment.invoiceNumber == null ? '' : 'lightgreen'}
                                                        onClick={() => {
                                                            if (postoServizioPayment.invoiceNumber === null) {
                                                                handleEditPayment(postoServizioPayment);
                                                            }
                                                        }}
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: 'lightgray',
                                                                cursor: postoServizioPayment.invoiceNumber === null ? 'pointer' : 'not-allowed'
                                                            },
                                                        }}
                                                    >
                                                        <TableCell> {postoServizioPayment.invoiceNumber} </TableCell>
                                                        <TableCell> {postoServizioPayment.code} </TableCell>
                                                        <TableCell> {getShortDate(postoServizioPayment.data)} </TableCell>
                                                        <TableCell> {placeDossierToSetService?.postoProprietarioNome} {placeDossierToSetService?.postoProprietarioCognome} </TableCell>
                                                        <TableCell> {postoServizioPayment.descrizione} </TableCell>
                                                        <TableCell align="right"> {getCurrencyByNumber(postoServizioPayment.importo)} </TableCell>
                                                        <TableCell align="right"> {TipoPagamento[postoServizioPayment.mezzo]} </TableCell>
                                                        <TableCell align="right">
                                                            {
                                                                postoServizioPayment.invoiceNumber === null || postoServizioPayment.invoiceNumber === undefined ?
                                                                    <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                                                        <IconButton onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            setEntityToRemoveData({
                                                                                id: postoServizioPayment.id!,
                                                                                name: postoServizioPayment.descrizione ?? ""
                                                                            });
                                                                            setRemovePaymentDialogOpen(true);
                                                                        }}>
                                                                            <DeleteTwoTone fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip> : <></>
                                                            }
                                                        </TableCell>
                                                    </AlternateTableRow>
                                                ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Collapse in={openEditPagamentoCollapse}>
                                <Box sx={{ m: 2 }}>
                                    {paymentToHandle !== undefined && <PagamentoUpsert isAdd={false} updateServizioPostoPagamenti={getPostoServizioPayments} pagamentoToUpdate={paymentToHandle} />}
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                </Slide>
            }
        </Drawer>
    </>);
}

export default PlacesStep;