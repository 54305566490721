import { Button, Card, CardContent, CardHeader, Grid, TablePagination, TextField, debounce, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DecuiusService } from "services/DecuiusServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Transfer } from "types/transfer";
import { getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import TransferRegisterPage from "./TransferRegisterPage";
import TransferRegisterPageMobile from "./TransferRegisterPageMobile";
import { useSelector } from "store";

interface TransferRegisterFilters {
    decuiusName: string;
    structureName: string;
}

const TransferRegisterList = () => {
    const decuiusService = new DecuiusService();

    const intl = useIntl();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const [transfers, setTransfers] = useState<PaginationResult<Transfer>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<TransferRegisterFilters>({
        decuiusName: "",
        structureName: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'transfersListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const getTransfers = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: TransferRegisterFilters) => {
        const retrievedTransfers = await decuiusService.GetAllTransfers(paginationQuery, filters);
        setTransfers(retrievedTransfers);

        setIsPageLoading(false);
    }, 700), []);

    const updateTransfers = () => {
        setIsPageLoading(true);
        getTransfers(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'transfersListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    useEffect(() => {
        updateTransfers();
    }, [paginationQuery, filters]);

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const emptyFilters = () => {
        setFilters({
            decuiusName: "",
            structureName: ""
        });
    }

    return <>
        <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="decuiusName" />}
                    name="decuiusName"
                    onChange={handleFiltersChange}
                    value={filters.decuiusName}
                />
            </Grid>

            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="structure" />}
                    name="structureName"
                    onChange={handleFiltersChange}
                    value={filters.structureName}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <Button variant="contained" onClick={emptyFilters}> <FormattedMessage id="emptyFilters" /> </Button>
            </Grid>
        </Grid>
        <Card>
            <CardHeader title={<FormattedMessage id="transferRegister" />} />
            <CardContent>
                {
                    mobileDevice ?
                        <TransferRegisterPageMobile
                            transfers={transfers}
                            isPageLoading={isPageLoading}
                        /> :
                        <TransferRegisterPage
                            transfers={transfers}
                            isPageLoading={isPageLoading}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                        />
                }
                <TablePagination
                    component="div"
                    count={transfers?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </CardContent>
        </Card>
    </>;
}

export default TransferRegisterList;