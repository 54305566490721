import { Accordion, AccordionSummary, AccordionDetails, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { UserPaymentsReport } from "types/userPaymentsReport";
import { PlaceServicePaymentsReport } from "types/placeServicePaymentsReport";
import { getShortDate } from "utils/common";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MoreVert } from "@mui/icons-material";
import { IconFiles } from "@tabler/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconFileSpreadsheet } from '@tabler/icons';

interface PlaceServiceWithMenuProps {
    placeService: PlaceServicePaymentsReport;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
}

const PlaceServiceWithMenu: FC<PlaceServiceWithMenuProps> = (props) => {
    const { placeService, downloadFattura } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    const navigate = useNavigate();

    return (
        <TableRow key={placeService.placeServiceId}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="dossier" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.dossierNumber} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="structure" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.structureDenomination} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="placeNumber" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.placeNumber} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.placeServiceDescription} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.placeServiceDate} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="year" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.year} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.placeServiceTotalAmount} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalPayed" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.placeServiceTotalPayed} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="invoiceNumber" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {placeService.invoiceNumber} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`placeService-actions-menu-${placeService.placeServiceId}`}
                    MenuListProps={{
                        'aria-labelledby': `placeService-action-button-${placeService.placeServiceId}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'goToDossier'} onClick={handleClose}>
                        <IconButton onClick={() => { navigate(`/yourDossiers/info?dossierId=${placeService.dossierId}`) }}>
                            <IconFiles fontSize="small" style={{ marginRight: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="goToDossier" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    {
                        placeService.invoiceId !== undefined && placeService.invoiceId !== null &&
                        <MenuItem key={'downloadInvoices'} onClick={handleClose}>
                            <IconButton onClick={() => { downloadFattura(placeService.invoiceId, `invoice-${placeService.invoiceNumber}.pdf`) }}>
                                <IconFileSpreadsheet fontSize="small" style={{ marginRight: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="downloadInvoice" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    }
                </Menu>
            </TableCell>
        </TableRow>
    );
};

interface PaymentsPageMobileProps {
    placeServices: UserPaymentsReport;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
}

const PaymentsPageMobile: FC<PaymentsPageMobileProps> = (props) => {
    const { placeServices, downloadFattura } = props;

    return (<>
        <Accordion defaultExpanded={placeServices.toPay.length > 0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="toPaySummary"
                id="toPaySummary"
            >
                <h3> <FormattedMessage id="toPay" /> ({placeServices.toPay.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {
                            <>
                                {
                                    placeServices &&
                                    placeServices.toPay &&
                                    placeServices.toPay.length > 0 &&
                                    placeServices.toPay.map((placeService: PlaceServicePaymentsReport, index: number) => (
                                        <PlaceServiceWithMenu
                                            key={index}
                                            placeService={placeService}
                                            downloadFattura={downloadFattura}
                                        />
                                    ))
                                }
                            </>
                        }
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="payedSummary"
                id="payedSummary"
            >
                <h3> <FormattedMessage id="payed" /> ({placeServices.payed.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {
                            <>
                                {
                                    placeServices &&
                                    placeServices.payed &&
                                    placeServices.payed.length > 0 &&
                                    placeServices.payed.map((placeService: PlaceServicePaymentsReport, index: number) => (
                                        <PlaceServiceWithMenu
                                            key={index}
                                            placeService={placeService}
                                            downloadFattura={downloadFattura}
                                        />
                                    ))
                                }
                            </>
                        }
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="paymentsToVerify"
                id="paymentsToVerify"
            >
                <h3> <FormattedMessage id="paymentsToVerify" /> ({placeServices.paymentsToVerify.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {
                            <>
                                {
                                    placeServices &&
                                    placeServices.paymentsToVerify &&
                                    placeServices.paymentsToVerify.length > 0 &&
                                    placeServices.paymentsToVerify.map((placeService: PlaceServicePaymentsReport, index: number) => (
                                        <PlaceServiceWithMenu
                                            key={index}
                                            placeService={placeService}
                                            downloadFattura={downloadFattura}
                                        />
                                    ))
                                }
                            </>
                        }
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    </>)
}

export default PaymentsPageMobile;